// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import LogIn from "views/auth/LogIn";

// Icon Imports
import {
  MdEmojiPeople,
  MdHome,
  MdLock,
  MdManageAccounts,
  MdOutlinePassword,
  MdOutlineVerifiedUser,
  MdSportsTennis,
  MdOutlineReportGmailerrorred
} from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import RolesAndPermissionManagement from "views/admin/RoleManagement";
import ChangePasswordForm from "views/auth/ChangePassword";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";

import { FaHandsHelping } from "react-icons/fa";
import { IoReceiptSharp } from "react-icons/io5";
import { RiMedalLine } from "react-icons/ri";
import CoachesManagement from "views/admin/CoachesManagement";
import CustomerManagement from "views/admin/CustomerManagement";
import HelpAndSupport from "views/admin/HelpAndSupport";
import IndependentSellersManagement from "views/admin/IndependentSellersManagement";
import ManageBookings from "views/admin/ManageBookings";
import SportsownerManagement from "views/admin/SportsownerManagement";
import EditSports from "views/admin/SportsownerManagement/Edit";
import AddSports from "views/admin/SportsownerManagement/New";
import VerifyUsers from "views/admin/VerifyUser";
import Questionary from "views/admin/questionary";
import ReportLinks from "views/admin/ReportLinks";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    isPublic: true,
  },
  {
    name: "Customer Management",
    layout: "/admin",
    path: "customer-management",
    icon: <MdManageAccounts className="h-6 w-6" />,
    component: <CustomerManagement />,
    isPublic: true,
  },
  {
    name: "Sport Owner Management",
    layout: "/admin",
    path: "sports-owner-management",
    icon: <MdSportsTennis className="h-6 w-6" />,
    component: <SportsownerManagement />,
    isPublic: true,
  },
  {
    name: "Sport Owner Management",
    layout: "/admin",
    path: "sports-owner-management/new",
    icon: <MdLock className="h-6 w-6" />,
    component: <AddSports />,
    isPublic: false,
  },
  {
    name: "Edit",
    layout: "/admin",
    path: "sports-owner-management/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <EditSports />,
    isPublic: false,
  },
  {
    name: "Coaches Management",
    layout: "/admin",
    path: "coaches-management",
    icon: <MdEmojiPeople className="h-6 w-6" />,
    component: <CoachesManagement />,
    isPublic: true,
  },
  {
    name: "Independent Sellers",
    layout: "/admin",
    path: "independent-sellers-management",
    icon: <RiMedalLine className="h-6 w-6" />,
    component: <IndependentSellersManagement />,
    isPublic: true,
  },
  {
    name: "Log In",
    layout: "/auth",
    path: "log-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <LogIn />,
    isPublic: false,
  },
  {
    name: "forgot",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
    isPublic: false,
  },
  {
    name: "Roles And Permissions",
    layout: "/admin",
    path: "roles-and-permission-management",
    icon: <MdLock className="h-6 w-6" />,
    component: <RolesAndPermissionManagement />,
    isPublic: true,
  },
  {
    name: "Verify Users",
    layout: "/admin",
    path: "verify-users",
    icon: <MdOutlineVerifiedUser className="h-6 w-6" />,
    component: <VerifyUsers />,
    isPublic: true,
  },
  {
    name: "Manage Bookings",
    layout: "/admin",
    path: "manage-bookings",
    icon: <IoReceiptSharp className="h-6 w-6" />,
    component: <ManageBookings />,
    isPublic: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "reset-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPassword />,
    isPublic: false,
  },
  {
    name: "Questionary",
    layout: "/admin",
    path: "questionary",
    icon: <RiQuestionnaireFill className="h-6 w-6" />,
    component: <Questionary />,
    isPublic: true,
  },
  {
    name: "Change Password",
    layout: "/admin",
    path: "change-password",
    icon: <MdOutlinePassword className="h-6 w-6" />,
    component: <ChangePasswordForm />,
    isPublic: true,
  },
  {
    name: "Help & Support",
    layout: "/admin",
    path: "help-and-support",
    icon: <FaHandsHelping className="h-6 w-6" />,
    component: <HelpAndSupport />,
    isPublic: true,
  },
  {
    name: "Report Links",
    layout: "/admin",
    path: "report-links",
    icon: <MdOutlineReportGmailerrorred className="h-6 w-6" />,
    component: <ReportLinks />,
    isPublic: true,
  },
];
export default routes;
