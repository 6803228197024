import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import {
  AiOutlineUserAdd
} from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiMinusCircle } from "react-icons/fi";
import ReactLoading from "react-loading";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";


const SportsownerManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");


  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);
  const [activateUserId, setActivateUserId] = useState(null);
  const [isActivating, setIsActivating] = useState(false);

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");

  const navigate = useNavigate();
  const showDeleteConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const showActivateConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setActivateUserId(id);
    setShowActiveConfirmation(true);
  };

  const hideActivateConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowActiveConfirmation(false);
  };

  const fetchRole = async () => {
    try {
      const response = await axiosInstance.get("/roles");
      const data = response?.data?.data;
      setRoleOptions(data);

      const customerRole = data?.find(
        (role) => role.name === "Sport Center Owner"
      );

      if (customerRole) {
        setSelectedRole(customerRole.id);
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const openModal = () => {
    navigate("new")
  };

  useEffect(() => {
    setData(data);
  }, [data]);
  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/users?page=${pageNumber}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
      let substring = `${sortBy}&sortType=${sortType}`;
      if (urlLink !== substring) {
        link = `/users?page=${1}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
        setUrl(substring);
      }
      if (selectedRole) {
        const response = await axiosInstance.get(link + substring);
        let users = response?.data?.data?.data;
        setTotalRowCount(response?.data?.data?.length);
        setShowNoDataText(!response?.data?.data?.length)
        setData(users);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [pageNumber, pageSize, selectedRole, isSorting]);

  const handleEdit = (row) => {
    navigate(`/admin/sports-owner-management/${row.id}`);

  };

  const handleActivateDeactivate = async (isDeleted, deleteUserId) => {
    try {
      const response = await axiosInstance.patch(`/users/${deleteUserId}`, {
        isDeleted,
      });

      if (response.status === 200) {
        fetchAllUsers();
        setDeleteUserId(null);
        setShowDeleteConfirmation(false);
      }

      toast.success(response?.data?.message);
    } catch (error) {
      toast.error("An error has been occurred.", {});
    }
  };

  const handleActivateUser = async () => {
    setIsActivating(true);
    await handleActivateDeactivate(false, activateUserId);
    setIsActivating(false);
    setShowActiveConfirmation(false);
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    await handleActivateDeactivate(true, deleteUserId);
    setIsDeleting(false);
  };

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === "asc") {
        setSortType("desc");
      } else if (sortType === "desc") {
        setSortBy("createdAt");
      }
    } else {
      setSortBy(by);
      setSortType("asc");
    }
    if (pageSize !== 1) setSorting(!isSorting);
    else {
      setPageSize(10);
      setPageNumber(1);
    }
  };

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const columns = [
    {
      name: "Owner Name",
      selector: (row) => row.name,
      sortable: true,
      sortFunction: () => sortTable("name"),
    },
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: false,
      sortFunction: () => sortTable("userName"),
    },
    {
      name: "Phone Number",
      selector: (row) => row.countryCode + " " + row.phoneNumber,
      sortable: false,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Court",
      selector: (row) => row?.court?.map(a => a.name),
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => {
        if (!row.isDeleted) {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(row)}
                className="bg-theme cursor-pointer rounded px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                <BiEdit />
              </button>
              <button
                onClick={() => showDeleteConfirmationModal(row.id)}
                className="hover-bg-red-700 cursor-pointer rounded bg-red-500 px-4 py-2 font-bold text-white"
              >
                <FiMinusCircle />
              </button>
            </div>
          );
        } else {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => showActivateConfirmationModal(row.id)}
                className="rounded bg-gray-500 px-5 py-2 font-bold text-white"
              >
                Deactive
              </button>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className="mt-3">
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className="flex items-center rounded-xl bg-[#4318FF] px-5 py-2 text-white hover:bg-blue-600 focus:outline-none"
        >
          <AiOutlineUserAdd className="mr-2 h-6 w-6" />
          Add Sports Owner
        </button>
      </div>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to deactive the sport owner ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isDeleting ? "Deactivating..." : "Yes"}
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                disabled={isDeleting}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showActiveConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to active the sports owner?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleActivateUser}
                disabled={isActivating}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isActivating ? "Activating..." : "Yes"}
              </button>
              <button
                onClick={hideActivateConfirmationModal}
                disabled={isActivating}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3 overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />
          }
          noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
            type={"bubbles"}
            color={"#4318FF"}
            height={100}
            width={100}
          />}
          onChangePage={(value) => {
            setPageNumber(value);
          }}
          paginationServer
          onChangeRowsPerPage={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
          paginationTotalRows={totalRowCount}
          paginationDefaultPage={pageNumber}
          customStyles={{
            rows: {
              style: {
                minHeight: "2.5rem",
              },
            },
            headCells: {
              style: {
                fontSize: "0.9rem",
                backgroundColor: "#4318FF",
                fontWeight: "bold",
                letterSpacing: "0.05rem",
                color: "#FFFFFF",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
            cells: {
              style: {
                fontSize: "0.9rem",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          }}
        />
      </div>
    </div >
  );
};

export default SportsownerManagement;
