import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";
import * as Yup from "yup";

const ResetPasswordForm = () => {
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
        "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password and Confirm Password must match.")
      .required("Confirm Password is required")
      .trim()
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const pass = values.password
    console.log(typeof pass);
    try {
      console.log("token", token);
      // Make API call to Reset password
      setLoading(true);
      const response = await axiosInstance.post(
        `/auth/reset-password?token=${token}&password=${values.password}`,
        {
          password: values.password,
        }
      );
      resetForm();
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      resetForm();
      console.log(values.password);
    }
    setLoading(false);
    setSubmitting(false);
  };

  return (
    <>
      <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
        <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
          <div className="rounded-xl border-red-600 bg-white p-6 shadow-lg dark:bg-navy-900 form-container md:w-[400px] mx-auto w-full">
            <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
              Reset Password
            </h4>
            <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
              Please Enter To Proceed Securly
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="mb-3">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password <span className="text-red-700">*</span>
                  </label>
                  <div className="relative">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      autoComplete="password"
                      placeholder="Enter Password"
                      className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                    />
                    <span
                      className="password-toggle-icon mt-1"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                    </span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-sm text-red-500"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password <span className="text-red-700">*</span>
                  </label>
                  <div className="relative">
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      autoComplete="confirmPassword"
                      placeholder="Enter Confirm Password"
                      className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                    />
                    <span
                      className="password-toggle-icon mt-1"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                    </span>
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-sm text-red-500"
                  />
                </div>

                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-blueSecondary py-[12px] text-base font-medium text-white transition duration-200 active:bg-[#14AADD] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
