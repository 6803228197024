import Footer from "components/footer/FooterAuthDefault";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="min-h-screen flex flex-col justify-between bg-white dark:bg-navy-900">
      <div className="flex flex-col items-center justify-center flex-grow">
        {/*<FixedPlugin />*/}
        <main className="mx-auto">
          <div className="flex flex-col max-w-[75%] mx-auto lg:max-w-[1013px] xl:max-w-full">
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="/auth/log-in" replace />} />
            </Routes>
          </div>
        </main>
      </div>
      <Footer />
    </div>

  );
}
