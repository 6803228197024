import Card from "components/card";
import BarChart from "components/charts/BarChart";
import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import { FaUserTie } from "react-icons/fa";
import { MdManageAccounts, MdOutlineBusiness, MdShowChart, MdSportsTennis } from "react-icons/md";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { fetchDashboardCounts } from "utils/services/dashboard.services";

const Dashboard = () => {

  const [dashboardCounts, setDashboardCounts] = useState(null);
  const [busyTimeData, setBusyTime] = useState(null);
  const [busyDays, setBusyDays] = useState(null);
  const [revenuePieChart, setRevenuePieChart] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()


  const fetchDashboardData = async () => {
    setIsLoading(true)
    const counts = await fetchDashboardCounts()
    setDashboardCounts(counts);
    setBusyTime(counts?.data?.busiestTime?.data);
    setBusyDays(counts?.data?.busiestDays?.data)
    setRevenuePieChart(counts?.data?.revenuePerSeller)
    setIsLoading(false)
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
        <div className="cursor-pointer" onClick={() => navigate('/admin/customer-management')}>
          <Widget
            icon={<MdManageAccounts className="h-6 w-6" />}
            title={"Total Customer Count"}
            subtitle={dashboardCounts?.data?.totalCustomers || 0}
          />
        </div>
        <div className="cursor-pointer" onClick={() => navigate('/admin/sports-owner-management')}>
          <Widget
            icon={<MdSportsTennis className="h-6 w-6" />}
            title={"Total Sport Owners"}
            subtitle={dashboardCounts?.data?.totalSportsCenters || 0}
          />
        </div>
        <div className="cursor-pointer" onClick={() => navigate('/admin/sports-owner-management')}>
          <Widget
            icon={<MdOutlineBusiness className="h-6 w-6" />}
            title={"Total Courts"}
            subtitle={dashboardCounts?.data?.totalCourts || 0}
          />
        </div>
        <div className="cursor-pointer" onClick={() => navigate('/admin/independent-sellers-management')}>
          <Widget
            icon={<FaUserTie className="h-6 w-6" />}
            title={"Total Independent Sellers"}
            subtitle={dashboardCounts?.data?.totalIndependentSellers || 0}
          />
        </div>
      </div>
      {/*
          Revenue Earned Per Customer Card
      */}
      {/*<div className="my-3">
        <Card extra="rounded-[20px] p-3">
          <div className="flex flex-col">
            <div className="mb-auto flex items-center justify-between px-6">
              <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                Revenue Earned Per Customer
              </h2>
            </div>

            <div className="w-full h-[380px]">
              {
                isLoading
                  ?
                  (
                    <div className="grid place-items-center h-full">
                      <ReactLoading
                        type={"spin"}
                        color={"#4318FF"}
                        height={60}
                        width={60}
                        delay={1.8}
                      />
                    </div>
                  )
                  : revenuePieChart && revenuePieChart?.length > 0
                    ?
                    (
                      <PieChart
                        options={{
                          chart: {
                            width: "100%",
                            height: "100%",
                            type: "donut",
                          },
                          labels: revenuePieChart?.map((item) => item?.sellerName) ?? [],
                          legend: {
                            show: false,
                            position: 'right',
                          },
                          dataLabels: {
                            enabled: true,
                            minAngleToShowLabel: 10,
                          },
                          plotOptions: {
                            pie: {
                              expandOnClick: false,
                            },
                          },
                          tooltip: {
                            enabled: true,
                            theme: "dark",
                            style: {
                              fontSize: "12px",
                              backgroundColor: "#ffffff",
                            },
                          },
                          colors: ["#4318ff", "#64B5F6", "#1E88E5", "#1976D2", "#0069FF"],
                          // colors: ["#4318ff", "#64B5F6", "#1E88E5", "#1976D2", "#1976D2"],
                        }}
                        series={revenuePieChart?.map((item) => item?.totalRevenue) ?? []}
                      />
                    )
                    :
                    (
                      <div className="text-center h-full grid place-items-center">
                        <p className="text-gray-400">No Revenues Found</p>
                      </div>
                    )
              }
            </div>*

          </div>
        </Card>
            </div>*/}

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 my-4">
        {/*
          Busiest Days Card
        */}
        <div>
          <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
            <div className="mb-auto flex items-center justify-between px-6">
              <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                Busiest Days
              </h2>
            </div>
            <div className="md:mt-16 lg:mt-0">
              <div className="h-[250px] w-full xl:h-[350px]">
                {
                  isLoading
                    ?
                    (
                      <div className="grid place-items-center h-full">
                        <ReactLoading
                          type={"spin"}
                          color={"#4318FF"}
                          height={60}
                          width={60}
                          delay={1}
                        />
                      </div>
                    )
                    : busyDays && busyDays?.length > 0
                      ?
                      (
                        <BarChart
                          chartData={[
                            {
                              name: "bookings",
                              data: busyDays?.map((item) => item.bookings) ?? [],
                              color: "#6AD2Fa",
                            }
                          ]}
                          chartOptions={{
                            chart: {
                              toolbar: {
                                show: false,
                              },
                            },
                            tooltip: {
                              style: {
                                fontSize: "12px",
                                fontFamily: undefined,
                                backgroundColor: "#000000"
                              },
                              intersect: false,
                              theme: 'dark',
                              onDatasetHover: {
                                style: {
                                  fontSize: "12px",
                                  fontFamily: undefined,
                                },
                              },
                            },
                            noData: {
                              text: 'No Data Found',
                              align: 'center',
                              verticalAlign: 'middle',
                              offsetX: 0,
                              offsetY: 0,
                              style: {
                                color: "#000",
                                fontSize: '14px',
                                fontFamily: undefined
                              }
                            },
                            xaxis: {
                              categories: busyDays?.map((item) => item.day) ?? [],
                              labels: {
                                style: {
                                  colors: "#A3AED0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                },
                              },
                              show: busyTimeData?.length > 0,
                            },
                            yaxis: {
                              categories: busyDays?.map((item) => item.bookings),
                              labels: {
                                style: {
                                  colors: "#A3AED0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                },
                                show: busyDays?.length > 0,
                              },
                            },
                            fill: {
                              type: "gradient",
                              gradient: {
                                type: "vertical",
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                colorStops: [
                                  [
                                    {
                                      offset: 0,
                                      color: "#4318ff",
                                      opacity: 1,
                                    },
                                    {
                                      offset: 100,
                                      color: "#2196f3",
                                      opacity: 0.7,
                                    },
                                  ],
                                ],
                              },
                            },
                            grid: {
                              borderColor: "rgba(163, 174, 208, 0.3)",
                              show: false,
                              yaxis: {
                                lines: {
                                  show: false,
                                  opacity: 0.5,
                                },
                              },
                              row: {
                                opacity: 0.5,
                              },
                              xaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                borderRadius: 10,
                                columnWidth: "40px",
                              },
                            },
                          }}
                        />
                      )
                      :
                      (
                        <div className="text-center h-full grid place-items-center">
                          <p className="text-gray-400">No Data Found</p>
                        </div>
                      )
                }
              </div>
            </div>
          </Card>
        </div>

        {/*
          Busiest Time Card
        */}
        <div>
          <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
            <div className="mb-auto flex items-center justify-between px-6">
              <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                Busiest Time
              </h2>
            </div>
            <div className="md:mt-16 lg:mt-0">
              <div className="h-[250px] w-full xl:h-[350px]">
                {
                  isLoading
                    ?
                    (
                      <div className="grid place-items-center h-full">
                        <ReactLoading
                          type={"spin"}
                          color={"#4318FF"}
                          height={60}
                          width={60}
                        />
                      </div>
                    )
                    :
                    busyTimeData && busyTimeData?.length > 0
                      ?
                      (
                        <BarChart
                          chartData={[
                            {
                              name: "bookings",
                              data: busyTimeData?.map((item) => item.bookings) ?? [],
                              color: "#6AD2Fa",
                            }
                          ]}
                          chartOptions={{
                            chart: {
                              toolbar: {
                                show: false,
                              },
                            },
                            tooltip: {
                              style: {
                                fontSize: "12px",
                                fontFamily: undefined,
                                backgroundColor: "#000000",
                              },
                              intersect: false,
                              theme: 'dark',
                              onDatasetHover: {
                                style: {
                                  fontSize: "12px",
                                  fontFamily: undefined,
                                },
                              },
                            },
                            noData: {
                              text: 'No Data Found',
                              align: 'center',
                              verticalAlign: 'middle',
                              offsetX: 0,
                              offsetY: 0,
                              style: {
                                color: "#000",
                                fontSize: '14px',
                                fontFamily: undefined
                              }
                            },
                            xaxis: {
                              categories: busyTimeData?.map((item) => item.timeSlot) ?? [],
                              labels: {
                                style: {
                                  colors: "#A3AED0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                },
                              },
                              show: busyTimeData?.length > 0,
                            },
                            yaxis: {
                              categories: busyTimeData?.map((item) => item.bookings),
                              labels: {
                                style: {
                                  colors: "#A3AED0",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                },
                                show: busyTimeData?.length > 0,
                              },
                            },
                            fill: {
                              type: "gradient",
                              gradient: {
                                type: "vertical",
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                colorStops: [
                                  [
                                    {
                                      offset: 0,
                                      color: "#4318ff",
                                      opacity: 1,
                                    },
                                    {
                                      offset: 100,
                                      color: "#2196f3",
                                      opacity: 0.7,
                                    },
                                  ],
                                ],
                              },
                            },
                            grid: {
                              borderColor: "rgba(163, 174, 208, 0.3)",
                              show: false,
                              yaxis: {
                                lines: {
                                  show: false,
                                  opacity: 0.5,
                                },
                              },
                              row: {
                                opacity: 0.5,
                              },
                              xaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                borderRadius: 10,
                                columnWidth: "40px",
                              },
                            },
                          }}
                        />
                      )
                      :
                      (
                        <div className="text-center h-full grid place-items-center">
                          <p className="text-gray-400">No Data Found</p>
                        </div>
                      )
                }
              </div>
            </div>
          </Card>
        </div>

        {
          /*
          <div>
            <Card extra="rounded-[20px] p-3">
              <div className="flex flex-col">
                <h4 className="text-lg font-bold text-navy-700 dark:text-white mb-2">
                  Revenue Earned per Customer
                </h4>
                <div>
                  <div className="flex justify-between">
                    <ReactDatePicker
                      className="w-[220px] bg-green-500"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                    />
                    <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                      <MdBarChart className="h-6 w-6" />
                    </button>
                  </div>
                  <PieChart options={pieChartOptions} series={pieChartCustomersRevenueData} />
                </div>
              </div>
            </Card>
          </div>  
          */
        }
      </div>
    </div >
  );
};

export default Dashboard;