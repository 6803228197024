// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA67g41m2g4iy1U03ptuR4Uq-3Hsz5J_FE",
    authDomain: "courtmate-b48f5.firebaseapp.com",
    projectId: "courtmate-b48f5",
    storageBucket: "courtmate-b48f5.appspot.com",
    messagingSenderId: "324614632724",
    appId: "1:324614632724:web:604b88f273d7fda386fe01",
    measurementId: "G-170HXP1JG6"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
let checkIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
let messaging;
if (!checkIOSDevice) {
    messaging = getMessaging(app);
}


const getDeviceFCM = async () => {
    const token = await getToken(messaging, {
        vapidKey: "BPtJBqotUZCashAHtgiakgOLgDZ-qro0lTEcf59TO2iV8qJMbnkBo--cbiES_KDU_30jIZ92hPHO5HZ5M3Bv2DY",
    })
    return token;
}

export { app, messaging, getDeviceFCM };

