import ScheduleIcon from "assets";
import { FaMinusCircle } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";

const CancellationAndRescheduling = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Cancellation and Rescheduling Policy</h1>
            <p className="mb-6 font-semibold">
                We recognize that schedules can change.
            </p>
            <div className="mb-6">
                <h2 className="flex items-center text-lg font-semibold mb-2">
                    <FaMinusCircle className="mr-2 text-blue-500" />
                    Cancellations:
                </h2>
                <ul className="list-disc pl-8 mb-4">
                    <li>
                        To receive a full refund for your reservation, cancellations must be made at least 24 hours prior to your reservation date. Please contact the sports center to cancel your reservation.
                    </li>
                </ul>
            </div>
            <div className="mb-6">
                <h2 className="flex items-center text-lg font-semibold mb-2">
                    <div className="text-blue-500 mr-2">
                        <ScheduleIcon />
                    </div>
                    Rescheduling:
                </h2>
                <ul className="list-disc pl-8 mb-4">
                    <li>
                        We are happy to help reschedule your reservation if needed. To reschedule, please contact the sports center at least 2 hours before your originally scheduled reservation time. We will do our utmost to find a suitable alternative time based on availability.
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="flex items-center text-lg font-semibold mb-2">
                    <FcInfo className="mr-2" />
                    How to Cancel:
                </h2>
                <ul className="list-disc pl-8 mb-4">
                    <li>
                        <span className="font-semibold">
                            For bookings made through Court Mate :
                        </span>{" "}You can conveniently cancel your reservation directly within the app. Navigate to the "My Activities &gt; Booking" page, locate the reservation you wish to cancel, and click the "Cancel" button.
                    </li>
                    <li>
                        <span className="font-semibold">
                            For bookings made directly with the sports center :
                        </span>{" "}Court Mate is not responsible or liable in any way for these bookings. Cancellations for these bookings must be made by contacting the sports center directly. Their contact information should be available at their profile page.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CancellationAndRescheduling;
