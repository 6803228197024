import dummyImage from "assets/img/avatars/UserAvtar.png";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";


const HelpAndSupport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);


  useEffect(() => {
    setData(data);
  }, [data]);

  const openModal = (row) => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    setSelectedCustomer(row)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedCustomer(null)
  };


  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/help?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}`;
      if (urlLink !== link) {
        link = `/help?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}`;
        setUrl(link);
      }
      const response = await axiosInstance.get(link);
      let users = response?.data?.data?.data;
      setTotalRowCount(response?.data?.data?.length);
      setShowNoDataText(!response?.data?.data?.length);
      setData(users);

    } catch (error) {
      toast.error(error?.response?.data?.message || "An unknown error occurred");
      setShowNoDataText(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [pageNumber, pageSize, isSorting]);

  // const sortTable = (by) => {
  //   if (by === sortBy) {
  //     if (sortType === "asc") {
  //       setSortType("desc");
  //     } else if (sortType === "desc") {
  //       setSortBy("createdAt");
  //     }
  //   } else {
  //     setSortBy(by);
  //     setSortType("asc");
  //   }
  //   if (pageSize !== 1) setSorting(!isSorting);
  //   else {
  //     setPageSize(10);
  //     setPageNumber(1);
  //   }
  // };

  const columns = [
    {
      name: "Profile Image",
      selector: (row) => (
        <img
          className="h-10 w-10 rounded-full"
          src={row?.user?.profilePic || dummyImage}
          alt="Profile"
        />
      ),
      sortable: false,
      id: "profileImage",
    },
    {
      name: "Username",
      selector: (row) => row?.user?.name || "-",
      sortable: false,
    },
    {
      name: "Subject",
      selector: (row) => row?.subject || "-",
      sortable: false,
    },
    {
      name: "Message",
      cell: (row) => (
        <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {row?.message || "-"}
        </div >
      ),
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="flex space-x-2">
            <button
              onClick={() => openModal(row)}
              className="cursor-pointer rounded bg-[#4318FF] px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              View More
            </button>
          </div>
        )
      },
    }
  ];

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>

      <div className="mt-3">
        <div className="mt-3 overflow-x-auto">
          <DataTable
            columns={columns}
            data={data}
            pagination
            progressPending={loading}
            paginationComponentOptions={paginationOptions}
            progressComponent={
              <ReactLoading
                type={"bubbles"}
                color={"#4318FF"}
                height={100}
                width={100}
              />
            }
            noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />}
            onChangePage={(value) => {
              setPageNumber(value);
            }}
            paginationServer
            onChangeRowsPerPage={(value) => {
              setPageSize(value);
              setPageNumber(1);
            }}
            paginationTotalRows={totalRowCount}
            paginationDefaultPage={pageNumber}
            customStyles={{
              rows: {
                style: {
                  minHeight: "2.5rem",
                },
              },
              headCells: {
                style: {
                  fontSize: "0.9rem",
                  backgroundColor: "#4318FF",
                  fontWeight: "bold",
                  letterSpacing: "0.05rem",
                  color: "#FFFFFF",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                },
              },
              cells: {
                style: {
                  fontSize: "0.9rem",
                  paddingLeft: "0.75rem",
                  paddingBottom: "0.50rem",
                  paddingTop: "0.50rem",
                  paddingRight: "0.75rem",
                },
              },
            }}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-lg rounded-lg bg-white p-4 shadow-lg">
            <div className="overflow-y-auto">
              <div className="relative flex flex-col px-5">
                <div className="mt-2">
                  {
                    selectedCustomer?.message
                  }
                </div>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="self-end rounded-full p-2 focus:outline-none font-light text-gray-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default HelpAndSupport;