const PrivacyPolicy = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Court Mate App User Policy</h1>
            <p className="mb-4">
                This policy outlines the acceptable use of the Court Mate app (License: 1010938991). By using Court Mate, you agree to these terms and conditions.
            </p>
            <h2 className="text-lg font-semibold mb-2">Content and Functionality</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>Court Mate is intended to facilitate booking and managing sports court reservations.</li>
                <li>You agree to use the app for lawful purposes only and in accordance with all applicable laws and regulations.</li>
                <li>You are prohibited from using the app to:
                    <ul className="list-disc pl-6">
                        <li>Transmit any illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive material.</li>
                        <li>Interfere with or disrupt the Court Mate app or servers or networks connected to the app.</li>
                        <li>Violate the intellectual property rights of others.</li>
                        <li>Gain unauthorized access to the app or any other account, network, or computer system.</li>
                        <li>Use the app for any commercial purposes without our express written consent.</li>
                    </ul>
                </li>
            </ul>
            {/* Add more sections as needed */}
            <h2 className="text-lg font-semibold mb-2">User Accounts and Responsibilities</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>You are responsible for maintaining the confidentiality of your account information and password.</li>
                <li>You are responsible for all activity that occurs under your account.</li>
                <li>You agree to notify us immediately of any unauthorized use of your account or any other security breach.</li>
            </ul>
            {/* Continue adding sections */}
            <h2 className="text-lg font-semibold mb-2">Security</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>We take your privacy seriously and use industry-standard security measures to protect your information. However, no online transmission is completely secure, so we cannot guarantee absolute security.</li>
            </ul>

            {/* Continue adding sections */}
            <h2 className="text-lg font-semibold mb-2">Third-Party Services</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>The Court Mate app may integrate with third-party services for functionalities like payment processing or map navigation.</li>
                <li>Your use of these third-party services may be subject to their own terms and conditions.</li>
                <li>We are not responsible for the content or practices of any third-party services.</li>
            </ul>

            {/* Add more sections as needed */}
            <h2 className="text-lg font-semibold mb-2">Modifications to the App and Policy</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>We reserve the right to modify or discontinue, temporarily or permanently, the Court Mate app or any part thereof with or without notice.</li>
                <li>We may also update this User Policy from time to time. You agree to be bound by any such revisions and should periodically visit this page to review the current terms.</li>
            </ul>

            <h2 className="text-lg font-semibold mb-2">Termination</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>We may terminate your access to the Court Mate app for violation of this User Policy or other applicable terms and conditions.</li>
            </ul>

            <h2 className="text-lg font-semibold mb-2">Disclaimer</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>The Court Mate app is provided "as is" and without warranties of any kind, express or implied.</li>
                <li>We disclaim all warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                <li>We will not be liable for any damages arising from the use of the Court Mate app.</li>
            </ul>

            {/* Continue adding sections */}
            <h2 className="text-lg font-semibold mb-2">Limitation of Liability</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>In no event shall we be liable for any indirect, incidental, consequential, special, exemplary, or punitive damages arising from or related to the use of the Court Mate app, even if we have been advised of the possibility of such damages.</li>
            </ul>

            <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
            <ul className="list-disc pl-6 mb-4">
                <li>If you have any questions about this User Policy, please contact us at <a href="mailto:admin@courtmate.co" className="text-blue-500">admin@courtmate.co</a>.</li>
            </ul>

        </div>
    )
}

export default PrivacyPolicy