import { onMessage } from "firebase/messaging";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Navigate, Route, Routes } from "react-router-dom";
import { getDeviceFCM, messaging } from "./firebase";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import CancellationAndRescheduling from "components/CancellationAndRescheduling/CancellationAndRescheduling";

const App = () => {
  const [isReRenderd, setIsReRenderd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkForServiceWorker = async () => {
    if (!('serviceWorker' in navigator)) {
      setIsLoading(false)
      return Error("No Support For The Service Worker!!");;
    }
    if (!('Notification' in window)) {
      return Error("No Support For Notifications!!");
    }
  };

  const registerSW = async () => {
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    return registration;
  };

  const subscribeToPushNotifications = async (registration) => {
    try {
      await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: "BPtJBqotUZCashAHtgiakgOLgDZ-qro0lTEcf59TO2iV8qJMbnkBo--cbiES_KDU_30jIZ92hPHO5HZ5M3Bv2DY"
      });

    } catch (error) {
      console.log(error);
    }
  };

  const initializeServiceWorker = async () => {
    try {
      setIsLoading(true);
      if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== "undefined") {
        console.log(window.navigator.serviceWorker);
        await checkForServiceWorker();
        let registration
        let serviceWorker
        if (!checkIOSDevice) {

          registration = await registerSW();

          serviceWorker = registration.installing || registration.waiting || registration.active;
        }

        if (serviceWorker && !checkIOSDevice) {

          try {
            await Notification.requestPermission();
            await navigator.serviceWorker.ready;
            setTimeout(() => {
              setIsReRenderd(true);
            }, 1000);
            if (serviceWorker.state === "activated" && !checkIOSDevice) {
              await subscribeToPushNotifications(registration);
              const currentToken = await getDeviceFCM();

              if (currentToken) {
                localStorage.setItem("deviceFCM", currentToken);
                setIsLoading(false)
                return
              }
            }

          } catch (error) {
            setIsLoading(false);
          }
          finally {
            setIsLoading(false)
          }

          if (serviceWorker.state !== "activated") {
            serviceWorker.addEventListener("statechange", async function (e) {
              if (e.target.state === "activated") {

                try {
                  await navigator.serviceWorker.ready;
                  await subscribeToPushNotifications(registration);
                  const currentToken = await getDeviceFCM();
                  if (!currentToken) {
                    window.location.reload();
                  }

                } catch (error) {
                }
              }
            });
          }

        }

        else {
          setIsLoading(false)

        }

      }
    }
    catch (error) {
    }
  }


  let checkIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  useEffect(() => {
    if (checkIOSDevice) {
      setIsLoading(false)
    }
    else {
      initializeServiceWorker();
      onMessage(messaging, (payload) => {
        // console.log('Message received:', payload.notification);
      });
    }
  }, []);

  return (
    <>
      <>
        {isLoading ?
          <div className="flex min-h-[100vh] items-center justify-center text-center">
            <ReactLoading
              type={"spin"}
              color={"#4318FF"}
              height={60}
              width={60}
            />
          </div>
          : (
            <Routes>
              <Route path="policy/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="policy/cancellation-and-rescheduling" element={<CancellationAndRescheduling />} />
              {localStorage.getItem("accessToken") ? (
                <>
                  <Route path="admin/*" element={<AdminLayout />} />
                  <Route
                    path="/"
                    element={<Navigate to="/admin/dashboard" replace />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/admin/dashboard" replace />}
                  />
                </>
              ) : (
                <>
                  <Route path="auth/*" element={<AuthLayout />} />
                  <Route path="/" element={<Navigate to="/auth/log-in" replace />} />
                  <Route path="*" element={<Navigate to="/auth/log-in" replace />} />
                </>
              )}
            </Routes>
          )}
      </>
    </>
  );
};


export default App;
