import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/services/api";
import { toast } from "react-toastify";
import { emailRegex } from "utils/constants";

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosInstance.post(`/auth/forgot-password`, values);

      if (response.status === 204) {
        // Display a custom success message for a 204 status code
        toast.success("Password reset link sent successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Display the success message from the backend
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      resetForm();
    } catch (error) {
      if (error.response?.data?.message) {
        // If there's an error message from the backend, display it
        toast.error(error.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // If no error message from the backend, display a custom message
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="rounded-xl border-red-600 bg-white p-6 shadow-lg dark:bg-navy-900">
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
            Forgot Password ?
          </h4>
          <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
            Lost your password? Enter your email to reset it securely.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address <span className="text-red-700">*</span>
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your Email"
                  className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>
              <button
                type="submit"
                className="linear mb-3 mt-2 w-full rounded-xl bg-blueSecondary py-[12px] text-base font-medium text-white transition duration-200 active:bg-[#14AADD] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Submit
              </button>
              <div className="text-center">
                <Link
                  to="/auth/log-in"
                  className="text-center text-sm font-medium text-[#06A7E0]"
                >
                  Back to log in ➔
                </Link>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
