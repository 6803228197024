const { default: axiosInstance } = require("./api");

export const fetchRole = async () => {
    try {
        const response = await axiosInstance.get("/roles");
        const data = response?.data?.data;
        return data
    } catch (error) {
        console.error("Error fetching role data:", error);
    }
};

export const fetchCourtList = async () => {
    try {
        const response = await axiosInstance.get("/courts/courtsList");
        if (response?.data?.success) {
            return response.data.data;
        } else {
            throw new Error(response?.data?.message || "Something went wrong");
        }
    } catch (error) {
        console.error("Error fetching court list:", error);
        throw new Error(error.response?.data?.message || "Something went wrong");
    }
};

export const removeFirstMatch = (originalString, substringToRemove) => {
    const regex = new RegExp(substringToRemove);
    return originalString.replace(regex, '');
}