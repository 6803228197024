import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/services/api';
import ReactLoading from 'react-loading'
import DataTable from 'react-data-table-component';
import { MdClose } from 'react-icons/md';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './style.css';

// import required modules
import { Navigation } from 'swiper/modules';

const ReportLinks = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNoDataText, setShowNoDataText] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRowCount, setTotalRowCount] = useState(10);
    const [sortBy, setSortBy] = useState("createdAt")
    const [sortType, setSortType] = useState("desc");
    const [urlLink, setUrl] = useState("")
    const [isSorting, setSorting] = useState(false);
    const [status, setStatus] = useState("all");
    const [statusChange, setStatusChange] = useState(false)
    // for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);


    useEffect(() => {
        setData(data);
    }, [data]);

    const openModal = (row) => {
        setIsModalOpen(true);
        document.body.classList.add("modal-open");
        setSelectedCustomer(row)
        console.log(selectedCustomer);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove("modal-open");
        setSelectedCustomer(null)
    };

    const fetchReportedData = async () => {
        setLoading(true);
        try {
            let link;
            if (status == 'all') {
                link = `/post/report?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}`;
                setUrl(link)
            }
            else {
                link = `/post/report?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}&status=${status}`;
                if (urlLink !== link) {
                    link = `/post/report?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}&status=${status}`;
                    setUrl(link);
                }
            }
            const response = await axiosInstance.get(link);
            let data = response?.data?.data?.data;
            setTotalRowCount(response?.data?.data?.length);
            setShowNoDataText(!response?.data?.data?.length);
            setData(data);


        } catch (error) {
            toast.error(error?.response?.data?.message || "An unknown error occurred");
            setShowNoDataText(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchReportedData();
    }, [pageNumber, pageSize, isSorting, status, statusChange]);

    const changeStatus = async (comeFrom) => {
        return axiosInstance.patch(`/post/report/${selectedCustomer?.id}`, {
            "status": comeFrom == "delete" ? "RESOLVED" : "IGNORED"
        })
            .then(response => {
                toast.success('Status updated successfully');
                closeModal()
                setStatusChange(!statusChange)
                return response.data;
            })
            .catch(error => {
                toast.error('Error updating question', error);
                return null;
            });
    }
    const columns = [
        {
            name: "Username",
            selector: (row) => row?.user?.userName || "-",
            sortable: false,
        },
        {
            name: "Report By",
            selector: (row) => (
                <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {row?.post?.author?.userName || "-"}
                </div >
            ),
            sortable: false,
        },
        {
            name: "Reason",
            selector: (row) => (
                <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {row?.reason || "-"}
                </div >
            ),
            sortable: false,
        },
        {
            name: "Status",
            cell: (row) => (
                <div className="whitespace-nowrap overflow-hidden overflow-ellipsis capitalize rounded-full text-gray-700 bg-[#c6c5ca] font-medium p-2">
                    {row.status || "-"}
                </div >
            ),
            sortable: false
        },
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="flex space-x-2">
                        <button
                            onClick={() => openModal(row)}
                            className="cursor-pointer rounded bg-[#4318FF] px-4 py-2 font-bold text-white hover:bg-blue-700"
                        >
                            View Post
                        </button>
                    </div>
                )
            },
        }
    ];

    console.log(statusChange, "statusChange");
    const paginationOptions = {
        rowsPerPageText: "Items Per Page",
        rangeSeparatorText: "of",
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    };
    const formatDateAndTime = (date) => {
        let convertedDate = new Date(date);
        let dateData = convertedDate.toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
        let timeData = convertedDate.toLocaleTimeString()
        return dateData + ' at ' + timeData
    }

    return (
        <>
            <div className='mt-3'>
                <select name="status" id="status" className='block w-50 p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ' value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="all">All</option>
                    <option value="RESOLVED">Resolved</option>
                    <option value="PENDING">Pending</option>
                    <option value="IGNORED">Ignored</option>
                </select>
            </div>
            <div className="mt-3">
                <div className="mt-3 overflow-x-auto">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        progressPending={loading}
                        paginationComponentOptions={paginationOptions}
                        progressComponent={
                            <ReactLoading
                                type={"bubbles"}
                                color={"#4318FF"}
                                height={100}
                                width={100}
                            />
                        }
                        noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
                            type={"bubbles"}
                            color={"#4318FF"}
                            height={100}
                            width={100}
                        />}
                        onChangePage={(value) => {
                            setPageNumber(value);
                        }}
                        paginationServer
                        onChangeRowsPerPage={(value) => {
                            setPageSize(value);
                            setPageNumber(1);
                        }}
                        paginationTotalRows={totalRowCount}
                        paginationDefaultPage={pageNumber}
                        customStyles={{
                            rows: {
                                style: {
                                    minHeight: "2.5rem",
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: "0.9rem",
                                    backgroundColor: "#4318FF",
                                    fontWeight: "bold",
                                    letterSpacing: "0.05rem",
                                    color: "#FFFFFF",
                                    paddingLeft: "0.75rem",
                                    paddingRight: "0.75rem",
                                },
                            },
                            cells: {
                                style: {
                                    fontSize: "0.9rem",
                                    paddingLeft: "0.75rem",
                                    paddingBottom: "0.50rem",
                                    paddingTop: "0.50rem",
                                    paddingRight: "0.75rem",
                                },
                            },
                        }}
                    />
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
                    <div className="relative z-10 w-full max-w-lg rounded-lg bg-white p-4 shadow-lg">
                        <div className="overflow-y-auto">
                            <div className="relative flex flex-col px-5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        closeModal();
                                        setSelectedCustomer(null);
                                    }}
                                    className="mt-2 self-end rounded-full text-gray-600 p-2 hover:text-red-600 focus:outline-none"
                                >
                                    <MdClose className="text-gray-600 hover:text-red-600" size={24} />
                                </button>
                                <div className="mb-3">
                                    <div className="head flex justify-start items-center mb-4">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src={selectedCustomer?.post?.author?.profilePic}
                                            alt="Profile"
                                        />
                                        <div className='ms-3'>
                                            <h1 className='font-medium text-xl'>{selectedCustomer?.post?.author?.userName}</h1>
                                            <p>{formatDateAndTime(selectedCustomer?.post?.createdAt)}</p>
                                        </div>
                                    </div>
                                    <Swiper

                                        navigation={true} modules={[Navigation]} className="mySwiper"
                                    >
                                        {selectedCustomer?.post?.imageUrl.map((item) =>
                                            <SwiperSlide key={item}>
                                                <img src={item} alt="post" className='h-80 w-full object-cover' />
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                    <div className="caption my-2 flex items-center justify-start">
                                        <h6 className='text-slate-700 font-medium'>Caption - </h6>
                                        <p className='text-slate-500 ms-1'>{selectedCustomer?.post?.caption}</p>
                                    </div>
                                    <div className="caption flex items-center justify-start">
                                        <h6 className='text-slate-700 font-medium'>Reason - </h6>
                                        <p className='text-slate-500 ms-1'>{selectedCustomer?.reason}</p>
                                    </div>
                                    <div className="buttons flex justify-end items-center space-x-2">
                                        {!selectedCustomer?.post?.isDeleted && (<button type="button" className="self-end rounded-full p-2 px-3 bg-red-500 font-light text-white hover:bg-red-700 disabled:bg-gray-500" onClick={() => changeStatus("delete")}>Delete</button>)}
                                        {
                                            selectedCustomer?.status !== "IGNORED" &&
                                            (
                                                <button type="button" className="self-end rounded-full p-2 px-3  font-light text-white hover:bg-blue-600 bg-[#4318FF]" onClick={() => changeStatus("ignore")}>Ignore</button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default ReportLinks