/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import Logo from "assets/img/brandLogos/Frame (1).png";
import routes from "routes.js";
import { useNavigate } from "react-router";

const Sidebar = ({ open, onClose }) => {

  const navigate = useNavigate()
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mt-[50px] w-full block`}
        onClick={() => {
          navigate("/admin/dashboard");
        }}
      >
        <img src={Logo} alt="Logo" className="w-[150px] mx-auto " />
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <div className="max-h-[calc(100vh-260px)] overflow-y-auto">
        <ul className="mb-auto">
          <Links routes={routes} />
        </ul>
      </div>

      {/* Nav item end */}
    </div >
  );
};

export default Sidebar;
