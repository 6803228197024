import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import {
  getQuestions,
  updateQuestion,
} from "utils/services/questionary.services";
import * as Yup from "yup";

const Questionary = () => {
  const [questionData, setQuestionData] = useState([]);
  const [faqId, setFaqId] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteQueIndex, setDeleteQueIndex] = useState(null);

  const fetchQuestionaryData = async () => {
    try {
      const response = await getQuestions();
      const formattedData = response?.data?.faq.map((questionObject) => ({
        question: questionObject.question,
        answer: (function () {
          // Immediately Invoked Function Expression (IIFE)
          const existingAnswers = questionObject.answer.map(
            (answerText, index) => ({
              option: String.fromCharCode(65 + index),
              text: answerText?.trim() || "",
            })
          );

          if (existingAnswers.length === 4) {
            return existingAnswers; // Use existing answers directly if there are four
          } else {
            const missingAnswers = [];
            for (let i = existingAnswers.length; i < 4; i++) {
              missingAnswers.push({
                option: String.fromCharCode(65 + i),
                text: "",
              });
            }
            return existingAnswers.concat(missingAnswers);
          }
        })(), // Call the IIFE to execute the logic
      }));

      setQuestionData(formattedData || []);
      setFaqId(response?.data?.id);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching question data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchQuestionaryData();
  }, []);

  const handleAddQuestion = () => {
    let isAnyTextFieldFilled;

    isAnyTextFieldFilled =
      questionData &&
      questionData[questionData?.length - 1]?.answer.some(
        (item) => item.text.trim() !== ""
      );
    if (
      questionData.length == 0 ||
      (questionData[questionData?.length - 1]?.question.length > 0 &&
        isAnyTextFieldFilled)
    ) {
      if (questionData.length < 3) {
        setQuestionData([
          ...questionData,
          {
            question: "",
            answer: [
              { option: "A", text: "" },
              { option: "B", text: "" },
              { option: "C", text: "" },
              { option: "D", text: "" },
            ],
          },
        ]);
      } else {
        toast.error("There are max 3 questions allowed");
      }
    } else {
      toast.warning("Already added blank question");
    }
  };

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        answers: Yup.array()
          .of(Yup.object().shape({}))
          .min(1, "minimum 1 answer required"),
        // answer: Yup.array()
        // .of(Yup.object().shape({
        // text: Yup.string().trim().min(2, 'Minimum of 2 answers required'),
        // }))
        // .min(2, 'Minimum of 2 answers required') // Minimum answer validation
        // .max(4, 'Maximum of 4 answers allowed') // Optional max limit (if needed)
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      questions: questionData,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let hasError = false;

      const faq = [];

      for (let index = 0; index < values.questions.length; index++) {
        const question = values.questions[index];
        const q = question.question;

        const answers = question.answer.map((ans) => ans.text.trim());
        const filteredAnswers = answers.filter((ans) => ans !== "");

        if (filteredAnswers.length < 2) {
          toast.error(`Add at least 2 options for Q.${index + 1}`);
          hasError = true;
          break; // Exit loop on first error
        }

        const isDuplicate = values.questions.some(
          (otherQuestion, otherIndex) => {
            return otherIndex !== index && otherQuestion.question === q;
          }
        );

        if (isDuplicate) {
          toast.error(`Duplicate question found for Q.${index + 1}`);
          hasError = true;
          break; // Exit loop on first error
        }
        faq.push({ question: q, answer: filteredAnswers });
      }

      if (hasError) {
        setLoading(false);
        return;
      }
      try {
        await updateQuestion(faqId, { faq });
      } catch (error) {
        toast.error("Error updating questions");
      }
      setLoading(false);
    },
  });

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  const handleDeleteQuestion = (index) => {
    document.body.classList.remove("modal-open");
    const arr = [...questionData];
    arr.splice(index, 1);
    setQuestionData(arr);
    handleSubmit();
    setShowDeleteConfirmation(false);
  };

  const showDeleteConfirmationModal = (index) => {
    document.body.classList.add("modal-open");
    setDeleteQueIndex(index);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowDeleteConfirmation(false);
    setDeleteQueIndex(null);
  };

  if (loading) {
    return (
      <div className="flex h-[75vh] items-center justify-center">
        <ReactLoading type={"spin"} color={"#4318FF"} height={60} width={60} />
      </div>
    );
  }
  return (
    <div className="mt-3">
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to delete question ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={() => {
                  handleDeleteQuestion(deleteQueIndex);
                }}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {questionData.length > 0 ? (
        <form onSubmit={handleSubmit}>
          <div className="mt-3 flex items-center justify-end">
            <button
              type="submit"
              className="rounded bg-[#4318FF] px-4 py-2 font-bold text-white"
            >
              Save
            </button>
            <div
              onClick={handleAddQuestion}
              className="ml-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-[#4318FF] text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </div>
          </div>
          {values.questions.map((q, index) => (
            <div key={index} className="flex flex-wrap justify-center">
              <div className="mt-5 w-full px-3 sm:w-9/12 md:mb-0">
                <label
                  htmlFor={`question${index}`}
                  className="mb-2 block text-xs font-bold tracking-wide text-gray-700"
                >
                  {index + 1}. Question
                </label>
                <input
                  id={`question${index}`}
                  name={`questions[${index}].question`}
                  type="text"
                  placeholder="Enter your question"
                  value={q.question}
                  className="block w-full appearance-none rounded border border-gray-300 bg-gray-100 px-3 py-2 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                  onChange={(e) => {
                    handleChange(e);
                    const arr = [...questionData];
                    arr[index].question = e.target.value;
                    setQuestionData(arr);
                  }}
                  onBlur={handleBlur}
                />
                {touched?.questions?.[index]?.question &&
                  errors?.questions?.[index]?.question && (
                    <div className="text-xs text-red-500">
                      {errors.questions[index].question}
                    </div>
                  )}
                <label
                  htmlFor={`answer${index}`}
                  className="mb-2 mt-2 block text-xs font-bold tracking-wide text-gray-700"
                >
                  Options
                </label>
                <div className="flex  flex-wrap justify-around gap-4">
                  {q?.answer?.map((answer, ansIndex) => (
                    <div key={ansIndex} className="w-2/5">
                      <div className="flex items-center gap-2">
                        <div className="text-sm font-bold tracking-wide text-gray-700">
                          {answer.option}.
                        </div>
                        <textarea
                          id={`answer${index}-${ansIndex}`}
                          name={`questions[${index}].answers[${ansIndex}].text`}
                          value={answer.text}
                          className="block w-full appearance-none rounded border border-gray-300 bg-gray-100 px-3 py-2 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                          placeholder={`Enter your option ${answer.option}`}
                          onChange={(e) => {
                            handleChange(e);
                            const arr = [...questionData];
                            arr[index].answer[ansIndex].text = e.target.value;
                            setQuestionData(arr);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {touched?.questions?.[index]?.answers?.[ansIndex]?.text &&
                        errors?.questions?.[index]?.answers?.[ansIndex]
                          ?.text && (
                          <div className="text-xs text-red-500">
                            {errors.questions[index].answers[ansIndex].text}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  // onClick={() => handleDeleteQuestion(index)}
                  onClick={() => showDeleteConfirmationModal(index)}
                  className="mt-2 text-sm text-red-500"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </form>
      ) : (
        <div className="flex h-[75vh] items-center justify-center">
          <div>
            <p>No Questions Here</p>
            <button
              onClick={handleAddQuestion}
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Add Question
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questionary;
