import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AiFillCloseSquare,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FiMinusCircle } from "react-icons/fi";
import ReactLoading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import { emailRegex, nameRegex } from "utils/constants";
import axiosInstance from "utils/services/api";
import { fetchCourtList, fetchRole, removeFirstMatch } from "utils/services/commonServices";
import { formatDateWithAMPM, getFormattedTime } from "utils/services/dateUtils";
import * as Yup from "yup";

const CoachesManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);
  const [activateUserId, setActivateUserId] = useState(null);
  const [isActivating, setIsActivating] = useState(false);

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");
  const [courtsList, setCourtsList] = useState([]);

  const inputRef = useRef(null);

  const decimalPlacesRegex = /^(0|\d+(\.\d{1,2})?)$/;

  const validationSchema = Yup.object().shape({
    courtId: Yup.string().required("Court is required"),
    coachName: Yup.string()
      .required("Coach Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Coach Name"
      )
      .test(
        "no-multiple-spaces",
        "Coach Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Coach Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "Coach Name must not exceed 20 characters"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),

    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits"),
    bio: Yup.string()
      .required("Bio is required")
      .max(250, "Bio must not exceed 250 characters"),
    languages: Yup.array()
      .of(Yup.string())
      .min(1, "At least one language must be selected"),
    paymentMethods: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Payment Option is Required"),
    pricing60Min: Yup.number()
      .typeError("Must be a number")
      .moreThan(1, "Price must be greater than 1")
      .positive("Price must be a positive number")
      .min(1, "Price must be greater than 1")
      .max(9999.00, "Must be less than or equal to 9999")
      .test("decimal-places", "Must have up to 2 decimal places", (value) =>
        decimalPlacesRegex.test(value)
      )
      .required("Required"),
    pricing120Min: Yup.number()
      .typeError("Must be a number")
      .positive("Price must be a positive number")
      .moreThan(1, "Price must be greater than 1")
      .min(1, "Price must be greater than 1")
      .max(9999.00, "Must be less than or equal to 9999")
      .test("decimal-places", "Must have up to 2 decimal places", (value) =>
        decimalPlacesRegex.test(value)
      )
      .required("Required"),
    days: Yup.array().of(
      Yup.object().shape({
        toTime: Yup.date().test(
          "not-equal",
          "From Time and To Time should not be the same",
          function (toTime) {
            const { fromTime } = this.parent;
            const formattedFromTime = getFormattedTime(fromTime);
            const formattedToTime = getFormattedTime(toTime);
            return toTime ? formattedToTime !== formattedFromTime : true;
          }
        ),
        fromTime: Yup.date().test(
          "not-equal",
          "Please Enter Valid From Time",
          function (fromTime) {
            const { toTime } = this.parent;
            const formattedFromTime = getFormattedTime(fromTime);
            const formattedToTime = getFormattedTime(toTime);

            if (formattedFromTime && formattedToTime) {
              if (formattedFromTime > formattedToTime) {
                return false;
              }
            }

            return true;
          }
        ),
      })
    ),
    startTime: Yup.date()
      .required("Start Time is required")
      .test(
        "not-equal",
        "Please Enter Valid From Time",
        function (startTime) {
          const { endTime } = this.parent;
          const formattedStartTime = getFormattedTime(startTime);
          const formattedEndTime = getFormattedTime(endTime);

          if (formattedStartTime && formattedEndTime) {
            if (formattedStartTime > formattedEndTime) {
              return false;
            }
          }

          return true;
        }
      ),
    endTime: Yup.date()
      .required("End Time is required").test(
        "not-equal",
        "From Time and To Time should not be the same",
        function (endTime) {
          const { startTime } = this.parent;
          const formattedStartTime = getFormattedTime(startTime);
          const formattedEndTime = getFormattedTime(endTime);
          return endTime ? formattedEndTime !== formattedStartTime : true;
        }
      ),

  });

  const EditValidationSchema = Yup.object().shape({
    courtId: Yup.string().required("Court is required"),
    coachName: Yup.string()
      .required("Coach Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Coach Name"
      )
      .test(
        "no-multiple-spaces",
        "Coach Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Coach Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "Coach Name must not exceed 20 characters"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),

    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits"),
    bio: Yup.string()
      .required("Bio is required")
      .max(250, "Bio must not exceed 250 characters"),
    languages: Yup.array()
      .of(Yup.string())
      .min(1, "At least one language must be selected"),
    paymentMethods: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Payment Option is Required"),
    pricing60Min: Yup.number()
      .typeError("Must be a number")
      .positive("Price must be a positive number")
      .moreThan(1, "Price must be greater than 1")
      .min(1, "Price must be greater than 1")
      .max(9999.00, "Must be less than or equal to 9999")
      .test("decimal-places", "Must have up to 2 decimal places", (value) =>
        decimalPlacesRegex.test(value)
      )
      .required("Required"),
    pricing120Min: Yup.number()
      .typeError("Must be a number")
      .positive("Price must be a positive number")
      .moreThan(1, "Price must be greater than 1")
      .min(1, "Price must be greater than 1")
      .max(9999.00, "Must be less than or equal to 9999")
      .test("decimal-places", "Must have up to 2 decimal places", (value) =>
        decimalPlacesRegex.test(value)
      )
      .required("Required"),
    days: Yup.array().of(
      Yup.object().shape({
        toTime: Yup.date().test(
          "not-equal",
          "From Time and To Time should not be the same",
          function (toTime) {
            const { fromTime } = this.parent;
            const formattedFromTime = getFormattedTime(fromTime);
            const formattedToTime = getFormattedTime(toTime);
            return toTime ? formattedToTime !== formattedFromTime : true;
          }
        ),
        fromTime: Yup.date().test(
          "not-equal",
          "Please Enter Valid From Time",
          function (fromTime) {
            const { toTime } = this.parent;
            const formattedFromTime = getFormattedTime(fromTime);
            const formattedToTime = getFormattedTime(toTime);

            if (formattedFromTime && formattedToTime) {
              if (formattedFromTime > formattedToTime) {
                return false;
              }
            }

            return true;
          }
        ),
      })
    ),

    startTime: Yup.date().test(
      "not-equal",
      "Please Enter Valid From Time",
      function (startTime) {
        const { endTime } = this.parent;
        const formattedStartTime = getFormattedTime(startTime);
        const formattedEndTime = getFormattedTime(endTime);

        if (formattedStartTime && formattedEndTime) {
          if (formattedStartTime > formattedEndTime) {
            return false;
          }
        }

        return true;
      }
    ),
    endTime: Yup.date().test(
      "not-equal",
      "From Time and To Time should not be the same",
      function (endTime) {
        const { startTime } = this.parent;
        const formattedStartTime = getFormattedTime(startTime);
        const formattedEndTime = getFormattedTime(endTime);
        return endTime ? formattedEndTime !== formattedStartTime : true;
      }
    ),
  });

  const languages = ["Arabic", "English"];
  const paymentMethods = ["Apple Pay", "Cash", "In-App"];

  const showDeleteConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const showActivateConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setActivateUserId(id);
    setShowActiveConfirmation(true);
  };
  const hideActivateConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowActiveConfirmation(false);
  };

  const getData = useCallback(async () => {
    const resp = await fetchRole();
    const courtListResponse = await fetchCourtList();
    setCourtsList(courtListResponse)
    const customerRole = resp?.find(
      (role) => role.name === "Sport Center Owner"
    );

    if (customerRole) {
      setSelectedRole(customerRole.id);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);


  const formatTime = (timeString) => {
    if (!timeString) {
      return "";
    }

    let [hours, minutes] = timeString.split(":");
    const isPM = timeString.toLowerCase().includes("pm");

    if (isPM && hours !== "12") {
      // Convert to 24-hour format if it's PM and not already 12 PM
      hours = String(parseInt(hours, 10) + 12);
    } else if (!isPM && hours === "12") {
      // Convert 12 AM to 24-hour format
      hours = "00";
    }

    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return date;
  };
  const formattedDays = selectedCustomer?.days?.map((i) => {
    return {
      ...i,
      fromTime: formatTime(i.fromTime),
      toTime: formatTime(i.toTime),
    };
  });

  const initialValues = {
    coachName: selectedCustomer?.coachName ? selectedCustomer.coachName : "",
    courtId: selectedCustomer?.courtId ? selectedCustomer.courtId : "",
    email: selectedCustomer?.email ? selectedCustomer.email : "",
    countryCode: editMode && selectedCustomer?.countryCode ? selectedCustomer?.countryCode : "+91",
    phoneNumber: editMode && selectedCustomer?.phoneNumber ? selectedCustomer?.countryCode?.split?.('+')?.[1] + selectedCustomer?.phoneNumber : "91",
    bio: selectedCustomer?.bio ? selectedCustomer.bio : "",
    languages: selectedCustomer?.languages ? selectedCustomer.languages : [],
    paymentMethods: selectedCustomer?.paymentMethods
      ? selectedCustomer.paymentMethods
      : [],
    pricing60Min: selectedCustomer?.pricing60Min
      ? selectedCustomer.pricing60Min
      : "",
    pricing120Min: selectedCustomer?.pricing120Min
      ? selectedCustomer.pricing120Min
      : "",
    days: selectedCustomer?.days ? formattedDays : [
      {
        day: "Monday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Tuesday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Wednesday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Thursday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Friday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Saturday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
      {
        day: "Sunday",
        fromTime: "",
        toTime: "",
        status: false,
        pricing60Min: 0,
        pricing120Min: 0
      },
    ],
    startTime: selectedCustomer?.startTime ? formatTime(selectedCustomer?.startTime) : "",
    endTime: selectedCustomer?.endTime ? formatTime(selectedCustomer?.endTime) : "",
  };



  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    setEditMode(false);
    setSelectedCustomer(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setEditMode(false);
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    try {
      let link = `/coach?page=${pageNumber}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
      let substring = `${sortBy}&sortType=${sortType}`;
      if (urlLink !== substring) {
        link = `/coach?page=${1}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
        setUrl(substring);
      }
      if (selectedRole) {
        const response = await axiosInstance.get(link + substring);
        setTotalRowCount(response?.data?.data?.length);
        setData(response?.data?.data?.data);
        setShowNoDataText(!response?.data?.data?.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }, [pageNumber, pageSize, selectedRole, sortBy, sortType, urlLink]);


  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, selectedRole, isSorting]);


  const handleEdit = (row) => {
    document.body.classList.add("modal-open");
    setSelectedCustomer(row);
    setIsModalOpen(true);
    setEditMode(true);
  };

  const handleActivateDeactivate = async (isDeleted, deleteUserId) => {
    try {
      const response = await axiosInstance.post(`/coach/update`, {
        isDeleted,
        id: deleteUserId,
      });
      if (response?.success === true) {
        setDeleteUserId(null);
        setShowDeleteConfirmation(false);
      }
      fetchAllUsers();
      toast.success(
        response?.data?.message ||
        (isDeleted ? "Coach deactivate successfully" : "Coach actived successfully")
      );
    } catch (error) {
      toast.error("An error occurred has been occured", {});
    }
  };

  const handleActivateUser = async () => {
    setIsActivating(true);
    await handleActivateDeactivate(false, activateUserId);
    setIsActivating(false);
    setShowActiveConfirmation(false);
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    await handleActivateDeactivate(true, deleteUserId);
    setIsDeleting(false);
    setShowDeleteConfirmation(false);
  };

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === "asc") {
        setSortType("desc");
      } else if (sortType === "desc") {
        setSortBy("createdAt");
      }
    } else {
      setSortBy(by);
      setSortType("asc");
    }
    if (pageSize !== 1) setSorting(!isSorting);
    else {
      setPageSize(10);
      setPageNumber(1);
    }
  };

  const columns = [
    {
      name: "Coach Name",
      selector: (row) => row.coachName,
      sortable: true,
      sortFunction: () => sortTable("coachName"),
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.countryCode + " " + row.phoneNumber,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Language Known",
      cell: (row) => (
        <div className="flex space-x-2">
          {row.languages.sort().map((language, index) => (
            <span
              key={index}
              className="w-25 rounded bg-red-400 px-2.5 py-0.5 text-white dark:bg-gray-700 dark:text-white"
            >
              {language}
            </span>
          ))}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => {
        if (!row.isDeleted) {
          return (
            <div className="flex space-x-2">
              <button
                id="editId"
                onClick={() => handleEdit(row)}
                className="bg-theme cursor-pointer rounded px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                <BiEdit />
              </button>
              <button
                onClick={() => showDeleteConfirmationModal(row.id)}
                className="hover-bg-red-700 cursor-pointer rounded bg-red-500 px-4 py-2 font-bold text-white"
              >
                <FiMinusCircle />
              </button>
            </div>
          );
        } else {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => showActivateConfirmationModal(row.id)}
                className="rounded bg-gray-500 px-5 py-2 font-bold text-white"
              >
                Deactive
              </button>
            </div>
          );
        }
      },
    },
  ];

  //   // const formattedTimeSlots = await days?.map((i) => {
  //   //   const obj = { ...i };
  //   //   const formattedFromTime = formatDateWithAMPM(i?.fromTime, "h:mm A");
  //   //   const formattedToTime = formatDateWithAMPM(i?.toTime, "h:mm A");
  //   //   if (!obj.status || (!formattedFromTime && !formattedToTime)) {
  //   //     obj.fromTime = "";
  //   //     obj.toTime = "";
  //   //   } else {
  //   //     obj.fromTime = formattedFromTime;
  //   //     obj.toTime = formattedToTime;
  //   //   }
  //   //   return obj;
  //   // });


  const handleFormSubmit = async (values) => {
    setUpdateLoading(true);
    const days = values?.days;

    const formattedTimeSlots = await days?.map((day) => {
      // Check if custom prices are provided for the day
      const customPricing60 = day?.customPricing60 !== undefined ? day.customPricing60 : day?.pricing60Min;
      const customPricing120 = day?.customPricing120 !== undefined ? day.customPricing120 : day?.pricing120Min;

      // Set pricing60Min and pricing120Min based on custom prices or default prices
      const pricing60Min = customPricing60 !== undefined && customPricing60 > 0 ? customPricing60 : values?.pricing60Min;
      const pricing120Min = customPricing120 !== undefined && customPricing120 > 0 ? customPricing120 : values?.pricing120Min;

      // Format fromTime and toTime or set default values
      const formattedFromTime = day.fromTime ? formatDateWithAMPM(day.fromTime, "h:mm A") : "";
      const formattedToTime = day.toTime ? formatDateWithAMPM(day.toTime, "h:mm A") : "";

      return {
        ...day,
        pricing60Min,
        pricing120Min,
        fromTime: formattedFromTime,
        toTime: formattedToTime,
        // Remove custom pricing if it exists in the day object
        customPricing60: undefined,
        customPricing120: undefined
      };
    });

    // Format payload
    const payload = { ...values, days: formattedTimeSlots };
    payload.startTime = formatDateWithAMPM(payload?.startTime, "h:mm A");
    payload.endTime = formatDateWithAMPM(payload?.endTime, "h:mm A");
    payload.phoneNumber = removeFirstMatch(values.phoneNumber, values.countryCode?.split('+')?.[1]) ?? '';
    payload.countryCode = values.countryCode;

    try {
      if (editMode && selectedCustomer) {
        const updateRequestData = {};
        Object.keys(payload).forEach((item) => {
          if (payload[item] !== selectedCustomer[item]) {
            updateRequestData[item] = payload[item];
          }
        });
        // Send the patch request to update the user
        const response = await axiosInstance.post(`/coach/update`, {
          id: selectedCustomer.id,
          ...updateRequestData,
        });
        closeModal();
        toast.success(response?.data?.message || "Coach Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // If not in edit mode, create a new user
        const response = await axiosInstance.post(`/coach`, payload);
        closeModal();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Fetch all users after successful submission
      fetchAllUsers();
    } catch (error) {
      // Handle errors and display a toast message
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // Set loading state to false after submission
    setUpdateLoading(false);
  };



  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <div className="mt-3">
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className="flex items-center rounded-xl bg-[#4318FF] px-5 py-2 text-white hover:bg-blue-600 focus:outline-none"
        >
          <AiOutlineUserAdd className="mr-2 h-6 w-6" />
          Add Coach
        </button>
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to deactive the coach ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isDeleting ? "Deactivating..." : "Yes"}
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                disabled={isDeleting}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showActiveConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to active the coach?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleActivateUser}
                disabled={isActivating}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isActivating ? "Activating..." : "Yes"}
              </button>
              <button
                onClick={hideActivateConfirmationModal}
                disabled={isActivating}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-3 overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />
          }
          noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
            type={"bubbles"}
            color={"#4318FF"}
            height={100}
            width={100}
          />}
          onChangePage={(value) => {
            setPageNumber(value);
          }}
          paginationServer
          onChangeRowsPerPage={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
          paginationTotalRows={totalRowCount}
          paginationDefaultPage={pageNumber}
          customStyles={{
            rows: {
              style: {
                minHeight: "2.5rem",
              },
            },
            headCells: {
              style: {
                fontSize: "0.9rem",
                backgroundColor: "#4318FF",
                fontWeight: "bold",
                letterSpacing: "0.05rem",
                color: "#FFFFFF",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
            cells: {
              style: {
                fontSize: "0.9rem",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          }}
        />
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-2xl overflow-hidden rounded-lg bg-white p-4 shadow-lg">
            <div className="h-[80vh] overflow-y-auto">
              <Formik
                initialValues={initialValues}
                validationSchema={
                  editMode ? EditValidationSchema : validationSchema
                }
                onSubmit={(values) => handleFormSubmit(values)}
                enableReinitialize
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setFieldError,
                  errors,
                }) => (
                  <Form className="flex flex-col px-5">
                    <button
                      type="button"
                      onClick={() => {
                        closeModal();
                        setSelectedCustomer(null);
                      }}
                      className="mt-4 self-end rounded-full bg-red-500 p-2 hover:bg-red-600 focus:outline-none"
                    >
                      <AiFillCloseSquare className="text-white" size={24} />
                    </button>

                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Roles:
                      </label>
                      <select
                        id="roleSelect"
                        className="w-full rounded-md border px-3 py-2"
                        value={selectedRole}
                        disabled={true}
                        ref={inputRef}
                      >
                        <option value="Sports Owner Management">
                          Sports Owner Management
                        </option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Select Court For the Coach: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        name="courtId"
                        className="w-full rounded-md border px-3 py-2"
                      >
                        <option value="" disabled>Select a court</option>
                        {courtsList.map((court, index) => (
                          <option key={index} value={court.id}>{court.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="courtId" component="div" className="text-red-500" />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        {`Coach Name:`} <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="coachName"
                        className="w-full rounded-md border px-3 py-2"
                        placeholder="Enter Coach Name"

                        maxLength={20}
                      />
                      <ErrorMessage
                        name="coachName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Email Address: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Mobile Number: <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <PhoneInput
                          country={values?.countryCode}
                          disableDropdown={false}
                          countryCodeEditable={false}
                          enableSearch={false}
                          placeholder="Enter Mobile Number"
                          value={values.phoneNumber}
                          onChange={(phone, dialCode, c, f) => {
                            setFieldValue("countryCode", `+${dialCode.dialCode}`);
                            setFieldValue("phoneNumber", phone);
                            if (
                              dialCode.format !==
                              String(phone).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          onBlur={async (e, dialCode) => {
                            if (
                              dialCode.format !==
                              String(e.target.value).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          containerStyle={{
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            height: "10px",
                          }}
                        />
                        {errors?.phoneNumber && (
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-red-500"
                          />
                        )}
                      </div>
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Bio: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="textarea"
                        name="bio"
                        className="w-full rounded-md border px-3 py-2"
                        placeholder="Enter Bio"
                        maxLength={250}
                      />
                      <ErrorMessage
                        name="bio"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Languages: <span className="text-red-500">*</span>
                      </label>
                      {languages.map((language) => (
                        <div key={language} className="flex items-center">
                          <Field
                            type="checkbox"
                            name="languages"
                            value={language}
                            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label className="ml-2">{language}</label>
                        </div>
                      ))}
                      <ErrorMessage
                        name="languages"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Payment Methods: <span className="text-red-500">*</span>
                      </label>
                      {paymentMethods.map((paymentMethod) => (
                        <div key={paymentMethod} className="flex items-center">
                          <Field
                            type="checkbox"
                            name="paymentMethods"
                            value={paymentMethod}
                            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label className="ml-2">{paymentMethod}</label>
                        </div>
                      ))}
                      <ErrorMessage
                        name="paymentMethods"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        60-minute Pricing:{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="number"
                        name="pricing60Min"
                        className="w-full rounded-md border px-3 py-2"
                        placeholder="Enter 60-minute pricing"
                      />
                      <ErrorMessage
                        name="pricing60Min"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        120-minute Pricing:{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="number"
                        name="pricing120Min"
                        className="w-full rounded-md border px-3 py-2"
                        placeholder="Enter 120-minute pricing"
                      />
                      <ErrorMessage
                        name="pricing120Min"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div>
                      <div className="flex space-x-4">
                        <div className="flex flex-1 flex-col">
                          <div
                            className="mb-1flex items-center rounded-lg border border-blue-300 bg-blue-50 p-2 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                            role="alert"
                          >
                            <label className="fw-bold mx-2 block text-gray-700">
                              Default Timing:
                            </label>
                          </div>

                          <div className="flex flex-col md:flex-row my-5">
                            {/* Start Time */}
                            <div className="mb-2 flex-1">
                              <label className="block text-gray-700 mb-1">Start Time <span className="text-red-500">*</span></label>
                              <ReactDatePicker
                                placeholderText="From Time"
                                selected={values?.startTime}
                                selectsStart
                                startDate={values?.startTime}
                                endDate={values?.endTime}
                                onChange={(date) => {
                                  setFieldValue(`startTime`, date);
                                  setFieldValue(`endTime`, "");
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="h:mm aa"
                                dateFormat="h:mm aa"
                                timeIntervals={30}
                                minTime={
                                  new Date(0, 0, 0, 0, 0)
                                }
                                maxTime={new Date(new Date().setHours(23, 59, 59))}
                                className="w-full rounded-md border px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"

                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                              <ErrorMessage
                                name="startTime"
                                component="div"
                                className="text-red-500"
                              />
                            </div>

                            {/* End Time */}
                            <div className="mb-2 flex-1">
                              <label className="block text-gray-700 mb-1">End Time <span className="text-red-500">*</span></label>
                              <ReactDatePicker
                                placeholderText="To Time"
                                selected={values?.endTime}
                                selectsEnd
                                startDate={values?.startTime}
                                endDate={values?.endTime}
                                onChange={(date) => {
                                  setFieldValue(`endTime`, date);
                                }}
                                disabled={values?.startTime === ""}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="h:mm aa"
                                dateFormat="h:mm aa"
                                timeIntervals={30}
                                minTime={
                                  values?.startTime
                                    ? new Date(values.startTime.getTime() + 5 * 60000) // Adding 5 minutes
                                    : new Date(0, 0, 0, 0, 0)
                                }
                                maxTime={new Date(new Date().setHours(23, 59, 59))}
                                className="w-full rounded-md border px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                              <ErrorMessage
                                name="endTime"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 bg-white">
                      {values?.days?.map((day, index) => (
                        <div key={index} className="mb-4 rounded-lg border p-4">
                          <div className="flex items-center justify-between">
                            <h3 className="mb-2 text-lg font-bold">{day?.day}</h3>
                            <div className="mb-2">
                              <Field
                                type="checkbox"
                                name={`days[${index}].status`}
                                checked={day?.status || (day?.fromTime || day?.toTime ? true : false)}
                                onChange={(e) => {
                                  setFieldValue(
                                    `days[${index}].status`,
                                    e.target.checked
                                  );
                                  if (e.target.checked) {
                                    setFieldValue(`days[${index}].toTime`, values.endTime || '');
                                    setFieldValue(`days[${index}].fromTime`, values.startTime || '');
                                    setFieldValue(`days[${index}].pricing120Min`, values.pricing120Min || '');
                                    setFieldValue(`days[${index}].pricing60Min`, values.pricing60Min || '');
                                    if (values.endTime === values.startTime) setFieldError(`days[${index}].fromTime`, "From Time and To Time should not be the same")

                                  } else {
                                    setFieldValue(`days[${index}].toTime`, '');
                                    setFieldValue(`days[${index}].fromTime`, '');

                                  }
                                }}
                              />
                              <ErrorMessage
                                name={`days[${index}].status`}
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                          <div className="mb-2">
                            <label className="block">From Time:</label>
                            <ReactDatePicker
                              selected={day?.fromTime}
                              onChange={(date) => {
                                setFieldValue(`days[${index}].fromTime`, date);
                                setFieldValue(`days[${index}].toTime`, "");
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="h:mm aa"
                              dateFormat="h:mm aa"
                              timeIntervals={30}
                              minTime={new Date(0, 0, 0, 0, 0)}
                              maxTime={new Date(new Date().setHours(23, 59, 59))}
                              className="w-full rounded-md border px-3 py-2"
                              disabled={!day.status && !day.fromTime && !day.toTime}
                              required
                            />
                            <ErrorMessage
                              name={`days[${index}].fromTime`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="mb-2">
                            <label className="block">To Time:</label>
                            <ReactDatePicker
                              selected={day.toTime}
                              onChange={(date) => {
                                setFieldValue(`days[${index}].toTime`, date);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              minTime={
                                day?.fromTime
                                  ? new Date(day?.fromTime.getTime() + 5 * 60000)
                                  : new Date(0, 0, 0, 0, 0)
                              }
                              maxTime={new Date(new Date().setHours(23, 59, 59))}
                              timeFormat="h:mm aa"
                              dateFormat="h:mm aa"
                              timeIntervals={30}
                              className="w-full rounded-md border px-3 py-2"
                              disabled={!day.status && !day.fromTime && !day.toTime}
                              required
                            />
                            <ErrorMessage
                              name={`days[${index}].toTime`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>

                          {/**/}
                          <label className="fw-bold mt-4 mb-2 block text-gray-700">Custom Price:</label>
                          <div className="mb-2">
                            <label className="fw-bold block text-gray-700">
                              60-minute Pricing:{" "}
                            </label>
                            <Field
                              type="number"
                              name={selectedCustomer?.days ? (values.days[index].status ? `days[${index}].pricing60Min` : '') : `days[${index}].customPricing60`}
                              className="w-full rounded-md border px-3 py-2"
                              onChange={handleChange}
                              disabled={!day.status && !day.fromTime && !day.toTime}
                              min={0}
                            />
                            <ErrorMessage
                              name={`days[${index}].customPricing60`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>

                          <div className="mb-2">
                            <label className="fw-bold block text-gray-700">
                              120-minute Pricing:{" "}

                            </label>
                            <Field
                              type="number"
                              name={selectedCustomer?.days ? (values.days[index].status ? `days[${index}].pricing120Min` : '') : `days[${index}].customPricing120`}
                              className="w-full rounded-md border px-3 py-2"
                              onChange={handleChange}
                              disabled={!day.status && !day.fromTime && !day.toTime}
                              min={0}
                            />
                            <ErrorMessage
                              name={`days[${index}].customPricing120`}
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      type="submit"
                      className="bg-theme cursor-pointer rounded-sm px-5 py-2 text-white"
                      disabled={updateloading}

                    >
                      {editMode
                        ? updateloading
                          ? "Updating..."
                          : "Update"
                        : updateloading
                          ? "Submitting..."
                          : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachesManagement;
