import { toast } from "react-toastify";
import axiosInstance from "./api";

export const getQuestions = () => {
    return axiosInstance.get('/questionary')
        .then(response => {
            return response.data;
        })
        .catch(error => {
            toast.error('Error fetching data', error);
            return null;
        });
};

export const updateQuestion = (questionId, updatedQuestionData) => {
    return axiosInstance.patch(`/questionary/${questionId}`, updatedQuestionData)
        .then(response => {
            toast.success('Question updated successfully');
            return response.data;
        })
        .catch(error => {
            toast.error('Error updating question', error);
            return null;
        });
};