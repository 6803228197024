import dummyImage from "assets/img/avatars/UserAvtar.png";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { IoLogOutSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const Navbar = (props) => {
  const { onOpenSidenav, brandText, handleLogout } = props;
  const navigator = useNavigate();

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>
      <div className="relative mt-[3px] flex h-[61px] w-[200px] flex-grow items-center justify-around gap-2 rounded-full px-2 py-2 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[200px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-6 w-6" />
        </span>
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={dummyImage}
              alt="Elon Musk"
            />
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
          onclick={() => navigator("/admin/profile")}
        />
        <button onClick={handleLogout} className="cursor-pointer">
          <IoLogOutSharp className="h-7 w-7 text-gray-600 dark:text-white" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
