import moment from 'moment';

export function formatDate(date, format) {
    return moment(date).format(format);
}

export function formatDateWithAMPM(date, format) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format(format);
}

export const getFormattedTime = (time) => {
    if (!time) return null;
    const Time = new Date(time);
    const hours = Time.getHours().toString().padStart(2, "0");
    const minutes = Time.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
};

export const parseDateString = (dateString) => {
    return dateString ? moment(dateString, 'YYYY-MM-DD HH:mm:ss').toDate() : null;
};