import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";
import { formatDate } from "utils/services/dateUtils";
import { AiFillCloseSquare } from "react-icons/ai";
import { FiMinusCircle } from "react-icons/fi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import './index.css';
import { FaEye } from 'react-icons/fa';

const ManageBookings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");

  const [showViewData, setShowViewData] = useState(false);
  const [viewData, setViewData] = useState(null);

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancelBookingData, setCancelBookingData] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false)

  const [searchTerm, setSearchTerm] = useState('');
  const [bookingDate, setBookingDate] = useState('');

  useEffect(() => {
    setData(data);
  }, [data]);


  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/book?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}&searchTerm=${searchTerm}&bookingDate=${bookingDate}`;
      if (urlLink !== link) {
        setUrl(link);
      }
      const response = await axiosInstance.get(link);
      let users = response?.data?.data?.data;
      setTotalRowCount(response?.data?.data?.length);
      setShowNoDataText(!response?.data?.data?.length);
      setData(users);
    } catch (error) {
      toast.error(error?.response?.data?.message || "An unknown error occurred");
      setShowNoDataText(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(pageNumber !== 1) {
      setPageNumber(1)
    }
  },[searchTerm,bookingDate])

  useEffect(() => {
    if(searchTerm == '' && bookingDate == '') 
    fetchAllUsers();
  }, [searchTerm, bookingDate,pageNumber, pageSize, isSorting]);
  
  useEffect(() => {
    if (searchTerm !== '' || bookingDate !== '') {
      setLoading(true);
      const searchData = setTimeout(() => {
        fetchAllUsers();
      }, 1000);
      return () => clearTimeout(searchData);
    }
  }, [searchTerm, bookingDate, pageNumber, pageSize, isSorting]);

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === "asc") {
        setSortType("desc");
      } else if (sortType === "desc") {
        setSortBy("createdAt");
      }
    } else {
      setSortBy(by);
      setSortType("asc");
    }
    if (pageSize !== 1) setSorting(!isSorting);
    else {
      setPageSize(10);
      setPageNumber(1);
    }
  };

  const showViewModal = (row) => {
    document.body.classList.add("modal-open");
    setShowViewData(true);
    setViewData(row);
  };

  const closeViewModal = () => {
    document.body.classList.remove("modal-open");
    setShowViewData(false);
    setViewData(null);
  };
  const showCancelModal = (row) => {
    document.body.classList.add("modal-open");
    setShowCancelConfirmation(true);
    setCancelBookingData({
      matchId: row?.matchId,
      bookingId: row?.id
    });
  }
  const closeCancelModal = () => {
    document.body.classList.remove("modal-open");
    setShowCancelConfirmation(false);
    setCancelBookingData(null);
  }

  const handleCancel = async () => {
    setIsDeleting(true)
    cancelBookingData.matchId ? await handleCancelBooking(true, cancelBookingData) : await handleCancelBooking(true, cancelBookingData);
    closeCancelModal();
    setIsDeleting(false);
  }

  const handleCancelBooking = async (isCancelled, cancelData) => {
    try {
      const response = await axiosInstance.patch(`book/${cancelData.bookingId}`, {
        isCancelled: isCancelled,
        matchId : cancelData.matchId
      } )
      if(response.status == 200){
        if(searchTerm === '' && bookingDate === ''){
          fetchAllUsers();
        }
        setSearchTerm('');
        setBookingDate('');
         (searchTerm !== '' || bookingDate !== '' ) && setPageNumber(1);
        }
      toast.success(response?.data?.message);      
    }
    catch (error) {
      toast.error("Error while cancel booking");
    }
  }

  const rowClass = "flex  w-full last:pb-0 border-b border-gray-600 last:border-0 hover:bg-gray-200";
  const keyCalss = 'w-1/2 text-center font-bold border-r border-gray-600 py-2'
  const valueClass = 'w-1/2 text-center py-2'
  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row?.bookingDate, "DD-MM-YYYY"),
      sortable: false,
      sortFunction: () => sortTable("bookingDate"),
    },
    {
      name: "Booking for",
      selector: (row) => (row?.guestUserName || row?.bookFor?.name) || "-",
      sortable: false,
    },
    {
      name: "Court",
      selector: (row) =>
        row?.pitch?.name ? row?.court?.name || row?.pitch?.court.name : "-",
      sortable: false,
    },
    {
      name: "Type",
      selector: (row) =>
      (row?.coachId ? (
        <h1 className="inline-flex rounded-full bg-green-200 px-2 text-xs font-semibold leading-5 text-green-800">
          Coach
        </h1>
      ) :
        (
          <h1 className="inline-flex rounded-full bg-green-200 px-2 text-xs font-semibold leading-5 text-green-800">
            Court
          </h1>
        ))
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2 mr-4">
          <button
            onClick={() => showViewModal(row)}
            className="bg-theme cursor-pointer rounded px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <FaEye />
          </button>
          <button
            onClick={() => showCancelModal(row)}
            disabled={row.isCancelled}
            className="hover-bg-red-700 cursor-pointer rounded bg-red-500 px-4 py-2 font-bold text-white disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            <FiMinusCircle />
          </button>
        </div>
      ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const validationSchema = Yup.object({
    searchData: Yup.string().required("Enter data for search")
  });

  return (
    <div className="mt-3 overflow-x-auto">
      {showViewData && (
        <div className="fixed inset-0 z-50 px-3 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-lg rounded-lg bg-white py-3 px-8 shadow-lg">
            <div className="viewModal max-h-[85vh] py-6 overflow-y-auto">
              <div className="flex justify-between mb-6">
                <p className="text-xl font-bold text-center mt-3 ml-3">More details</p>
                <button
                  type="button"
                  onClick={() => {
                    closeViewModal();
                  }}
                  className="self-end rounded-full bg-red-500 p-2 hover:bg-red-600 focus:outline-none"
                >
                  <AiFillCloseSquare className="text-white" size={20} />
                </button>
              </div>
              <div className="booking--details border border-gray-600 rounded-md mb-5">
                <h2 className="text-center text-ld font-bold rounded-t-md bg-blueSecondary text-white py-2">Booking Details</h2>
                <div>
                  <div className={rowClass}>
                    <p className={keyCalss} >Name</p>
                    <p className={valueClass}>{(viewData?.pitch?.name || viewData?.coach?.coachName || viewData?.court?.name) || "-"}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Book by</p>
                    <p className={valueClass}>{viewData?.bookBy?.name}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Book for</p>
                    <p className={valueClass}>{viewData?.guestUserName || viewData?.bookFor?.name || "-"}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Court</p>
                    <p className={valueClass}>{viewData?.pitch?.name ? viewData?.court?.name || viewData?.pitch?.court.name : "-"}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Type</p>
                    <p className={valueClass}>
                      {(viewData?.coachId ? ('Coach') : ('Court'))}
                    </p>
                  </div>
                  <div className={rowClass}>
                    <div className={keyCalss}>Phone</div>
                    <div className={valueClass}>
                      {viewData?.guestUserNumber ||
                        viewData?.bookFor?.phoneNumber ||
                        "-"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="timing--details border border-gray-600 rounded-md mb-5">
                <h2 className="text-center text-ld font-bold rounded-t-md bg-blueSecondary text-white py-2">Timing details</h2>
                <div>
                  <div className={rowClass} >
                    <p className={keyCalss}>Booking Date</p>
                    <p className={valueClass}>{formatDate(viewData?.bookingDate, "DD-MM-YYYY")}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Duration</p>
                    <p className={valueClass}>{viewData?.duration}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Start time</p>
                    <p className={valueClass}>{(viewData?.startTime) || "-"}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>End time</p>
                    <p className={valueClass}>{(viewData?.endTime) || "-"}</p>
                  </div>
                </div>
              </div>

              <div className="payment--details border border-gray-600 rounded-md">
                <h2 className="text-center text-ld font-bold rounded-t-md bg-blueSecondary text-white py-2">Payment details</h2>
                <div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Payment</p>
                    <p className={valueClass}>
                      {viewData?.amountType ? viewData?.amountType === "PARTLY_PAID" ? "Unpaid" : viewData?.amountType === "ALREADY_PAID"
                        ? "Paid" : viewData?.amountType === "UNPAID" ? "Unpaid" : viewData?.amountType : "-"}
                    </p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Fee</p>
                    <p className={valueClass}> {viewData?.bookingFee + " " + viewData?.currency} </p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Fee Received</p>
                    <p className={valueClass}>{viewData?.feeReceived || "-"}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Fee Pending</p>
                    <p className={valueClass}>{viewData?.isFeePaid ? '-' : (viewData?.feePending || '-')}</p>
                  </div>
                  <div className={rowClass}>
                    <p className={keyCalss}>Method</p>
                    <p className={valueClass}>{viewData.paymentMethod || "-"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCancelConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to cancel booking ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleCancel}
                disabled={isDeleting}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isDeleting ? "Canceling..." : "Yes"}
              </button>
              <button
                onClick={closeCancelModal}
                disabled={isDeleting}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <div className="Search mb-3">
          <Formik
            initialValues={{
              searchData: '',
              bookingDate: Date.now()
            }}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, e) => {
              e.preventDefault();
              setSearchTerm(values.searchData)
            }
            }
          >
            <Form className="flex justify-between">
            <div className="flex gap-4 flex-wrap max-w-[560px]">
            <Field id="searchField" name="searchData" type="text" className="rounded-md w-[260px] px-3 py-2 border focus:outline-blueSecondary" 
            placeholder="Search court"
            value={searchTerm}
            onChange = {(e) => {
              setSearchTerm(e.target.value);
              }}
              />
              <div className="flex items-center">
                  <label htmlFor="bookingDate" className="font-medium text-gray-400 w-28">Booking date :</label>
                  <Field name='bookingDate' id='bookingDate'
                  type='date'className=' rounded-md px-3 py-2 border focus:outline-blueSecondary' value={bookingDate}
                  onChange = {(e) => {
                    setBookingDate(e.target.value);
                  }}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          page={pageNumber}
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />
          }
          noDataComponent={
            showNoDataText ? (
              <div>There are no records to display</div>
            ) : (
              <ReactLoading
                type={"bubbles"}
                color={"#4318FF"}
                height={100}
                width={100}
              />
            )
          }
          onChangePage={(value) => {
            setPageNumber(value);
          }}
          paginationServer
          onChangeRowsPerPage={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
          paginationTotalRows={totalRowCount}
          paginationDefaultPage={pageNumber}
          customStyles={{
            rows: {
              style: {
                minHeight: "2.5rem",
              },
            },
            headCells: {
              style: {
                fontSize: "0.9rem",
                backgroundColor: "#4318FF",
                fontWeight: "bold",
                letterSpacing: "0.05rem",
                color: "#FFFFFF",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
            cells: {
              style: {
                fontSize: "0.9rem",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ManageBookings;
