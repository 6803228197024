import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import axiosInstance from "utils/services/api";
import ReactLoading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  AiFillCloseSquare,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { FiMinusCircle } from "react-icons/fi";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { MdDriveFolderUpload } from "react-icons/md";
import { emailRegex } from "utils/constants";
import { userNameRegex } from "utils/constants";
import { nameRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/commonServices";

const IndependentSellersManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);
  const [activateUserId, setActivateUserId] = useState(null);
  const [isActivating, setIsActivating] = useState(false);
  const [deletepdfId, setDeletepdfId] = useState(-1);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");

  const [selectedPdfNames, setSelectedPdfNames] = useState([]);
  const [icon, setIcon] = useState(null)

  const pdfInputRef = useRef(null);
  const inputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Owner Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Owner Name"
      )
      .test(
        "no-multiple-spaces",
        "Owner Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Owner Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "Owner Name must not exceed 20 characters"),
    userName: Yup.string()
      .required("User Name is required")
      .matches(
        userNameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the User Name"
      )
      .test(
        "no-only-special-chars",
        "User Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "User Name must not exceed 20 characters")
      .test(
        "no-multiple-spaces",
        "User Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),

    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits"),
    password: Yup.string()
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
        "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
      ),
    businessName: Yup.string().trim().required("Business Name is Required"),
    businessAddress: Yup.string().trim(),
    businessDescription: Yup.string().trim(),
    website: Yup.string(),
    instagram: Yup.string(),
    WhatsApp: Yup.string(),
    snapchat: Yup.string(),
  });

  const EditValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Owner Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Owner Name"
      )
      .test(
        "no-multiple-spaces",
        "Owner Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Owner Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(35, "Owner Name must not exceed 35 characters"),
    userName: Yup.string()
      .required("User Name is required")
      .matches(
        userNameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the User Name"
      )
      .test(
        "no-only-special-chars",
        "User Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(35, "User Name must not exceed 35 characters")
      .test(
        "no-multiple-spaces",
        "User Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),

    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits")
  });

  const handlePdfInputChange = async (event) => {
    const files = event.currentTarget.files;
    const allowedFormats = ["application/pdf"];

    if (!files || files.length === 0) return;

    const validFiles = Array.from(files).filter((file) =>
      allowedFormats.includes(file.type)
    );

    if (validFiles.length > 0) {
      let file = selectedPdfNames;
      if (file.length < 5) {
        setSelectedPdfNames((prevState) => [...prevState, ...validFiles]);
      } else {
        toast.warn("Please do not add more 5 PDF files");
      }
    } else {
      toast.error("Invalid file format. Please upload a pdf file.");
    }
  };

  const handleViewPdf = (pdf) => {
    if (pdf.location) {
      const pdfUrl = pdf.location;
      window.open(pdfUrl, "_blank");
    } else {
      const pdfBlob = new Blob([pdf], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    }
  };

  const handleDeletePdf = async (index) => {
    try {
      let docId = selectedPdfNames[index]?.id;
      if (docId) {
        setDeletepdfId(index);
        const response = await axiosInstance.delete(
          `/users/delete-document/${docId}`
        );

        if (response && response.message) {
          toast.success(response.message);
        } else {
          toast.success("Document deleted successfully");
        }
        setDeletepdfId(-1);
      } else {
        const pdfInput = pdfInputRef.current;
        if (pdfInput) {
          pdfInput.value = "";
        }
      }
      setSelectedPdfNames((prevNames) => {
        const updatedNames = [...prevNames];
        updatedNames.splice(index, 1);
        return updatedNames;
      });
    } catch (error) {
      console.error("Error deleting document:", error);

      if (error.response) {
        toast.error(`Server error: ${error.response.status}`);
      } else if (error.request) {
        toast.error("No response from the server");
      } else {
        toast.error(
          error.message || "Error deleting document. Please try again later."
        );
      }
    }
  };

  const showDeleteConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const showActivateConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setActivateUserId(id);
    setShowActiveConfirmation(true);
  };

  const hideActivateConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowActiveConfirmation(false);
  };

  const fetchRole = async () => {
    try {
      const response = await axiosInstance.get("/roles");
      const data = response?.data?.data;
      setRoleOptions(data);

      const customerRole = data?.find(
        (role) => role.name === "Independent Sellers"
      );

      if (customerRole) {
        setSelectedRole(customerRole.id);
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    roleId: selectedRole,
    name: selectedCustomer ? selectedCustomer.name : "",
    userName: selectedCustomer ? selectedCustomer.userName : "",
    email: selectedCustomer ? selectedCustomer.email : "",
    countryCode: editMode && selectedCustomer?.countryCode ? selectedCustomer?.countryCode : "+91",
    phoneNumber: editMode && selectedCustomer?.phoneNumber ? selectedCustomer?.countryCode?.split?.('+')?.[1] + selectedCustomer?.phoneNumber : "91",
    password: selectedCustomer ? selectedCustomer.password : "",
    businessName: selectedCustomer
      ? selectedCustomer.businessInformation?.name
      : "",
    businessAddress: selectedCustomer
      ? selectedCustomer.businessInformation?.address
      : "",
    businessDescription: selectedCustomer
      ? selectedCustomer.businessInformation?.description
      : "",
    Website: selectedCustomer ? selectedCustomer.socialMedialinks?.Website : "",
    Instagram: selectedCustomer
      ? selectedCustomer.socialMedialinks?.Instagram
      : "",
    WhatsApp: selectedCustomer
      ? selectedCustomer.socialMedialinks?.WhatsApp
      : "",
    Snapchat: selectedCustomer
      ? selectedCustomer.socialMedialinks?.Snapchat
      : "",
  };

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    setEditMode(false);
    setSelectedCustomer(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPdfNames([]);
    document.body.classList.remove("modal-open");
    setEditMode(false);
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/users?page=${pageNumber}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
      let substring = `${sortBy}&sortType=${sortType}`;
      if (urlLink !== substring) {
        link = `/users?page=${1}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
        setUrl(substring);
      }
      if (selectedRole) {
        const response = await axiosInstance.get(link + substring);
        let users = response?.data?.data?.data;
        setTotalRowCount(response?.data?.data?.length);
        setData(users);
        setShowNoDataText(!response?.data?.data?.length)
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchAllUsers();
  }, [pageNumber, pageSize, selectedRole, isSorting]);

  const handleEdit = (row) => {
    setIcon(row.businessInformation.icon)
    setSelectedCustomer(row);
    if (row?.document) {
      setSelectedPdfNames(row.document);
    }
    document.body.classList.add("modal-open");
    setIsModalOpen(true);
    setEditMode(true);
  };

  const handleActivateDeactivate = async (isDeleted, deleteUserId) => {
    try {
      const response = await axiosInstance.patch(`/users/${deleteUserId}`, {
        isDeleted,
      });

      if (response.status === 200) {
        fetchAllUsers();
        setDeleteUserId(null);
        setShowDeleteConfirmation(false);
      }

      toast.success(response?.data?.message);
    } catch (error) {
      toast.error("An error occurred while deleting the customer.", {});
    }
  };

  const handleActivateUser = async () => {
    setIsActivating(true);
    await handleActivateDeactivate(false, activateUserId);
    setIsActivating(false);
    setShowActiveConfirmation(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await handleActivateDeactivate(true, deleteUserId);
    setIsDeleting(false);
  };

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === "asc") {
        setSortType("desc");
      } else if (sortType === "desc") {
        setSortBy("createdAt");
      }
    } else {
      setSortBy(by);
      setSortType("asc");
    }
    if (pageSize !== 1) setSorting(!isSorting);
    else {
      setPageSize(10);
      setPageNumber(1);
    }
  };

  const columns = [
    {
      name: "Owner Name",
      selector: (row) => row.name,
      sortable: true,
      sortFunction: () => sortTable("name"),
    },
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: false,
      sortFunction: () => sortTable("userName"),
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.countryCode + " " + row.phoneNumber,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => {
        if (!row.isDeleted) {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(row)}
                className="bg-theme cursor-pointer rounded px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                <BiEdit />
              </button>
              <button
                onClick={() => showDeleteConfirmationModal(row.id)}
                className="hover-bg-red-700 cursor-pointer rounded bg-red-500 px-4 py-2 font-bold text-white"
              >
                <FiMinusCircle />
              </button>
            </div>
          );
        } else {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => showActivateConfirmationModal(row.id)}
                className="rounded bg-gray-500 px-5 py-2 font-bold text-white"
              >
                Deactive
              </button>
            </div>
          );
        }
      },
    },
  ];

  const showFiles = () => {
    if (selectedPdfNames.length > 0)
      return (
        <>
          <div className="mb-3">
            {selectedPdfNames.map((file, index) => (
              <>
                <div
                  className="mb-2 flex justify-between rounded-md border-[2px] border-gray-100 px-4 py-1 outline-none items-center flex-col"
                >
                  <div className="overflow-hidden w-full">
                    <p className="break-words">{file.name}</p>
                  </div>
                  <div className="flex justify-end w-full gap-3">
                    <div>
                      <button
                        type="button"
                        className="font-light text-gray-600"
                        onClick={() => handleViewPdf(file)}
                      >
                        View
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        disabled={deletepdfId === index}
                        className="font-light text-gray-600"
                        onClick={() => handleDeletePdf(index)}
                      >
                        {deletepdfId === index ? "Deleting..." : "Delete"}
                      </button>
                    </div>
                  </div>

                </div>
              </>
            ))}
          </div>
        </>
      );
  };
  const handleFormSubmit = async (values) => {
    setUpdateLoading(true);

    const updateFormdata = new FormData();

    if (selectedCustomer) {
      if (values.name !== selectedCustomer.name) {
        updateFormdata.append("name", values.name);
      }
      if (values.userName !== selectedCustomer.userName) {
        updateFormdata.append("userName", values.userName);
      }
      if (values.email !== selectedCustomer.email) {
        updateFormdata.append("email", values.email);
      }
      if (values.phoneNumber !== selectedCustomer.phoneNumber) {
        updateFormdata.append("phoneNumber", removeFirstMatch(values?.phoneNumber, values.countryCode?.split('+')?.[1]) ?? '');
      }
      if (values.password !== selectedCustomer.password) {
        updateFormdata.append("password", values.password);
      }
      if (
        values.document !== selectedCustomer.document &&
        selectedPdfNames.length > 0
      ) {
        for (let i = 0; i < selectedPdfNames.length; i++) {
          !selectedPdfNames[i].id &&
            updateFormdata.append("document", selectedPdfNames[i]);
        }
      }

      if (values.countryCode !== selectedCustomer.countryCode) {
        updateFormdata.append("countryCode", values.countryCode);
      }
      if (
        values.businessAddress !== "" ||
        selectedCustomer?.businessInformation?.address !==
        values.businessAddress ||
        values.businessName !== "" ||
        selectedCustomer?.businessInformation?.name !== values.businessName ||
        values.businessDescription !== "" ||
        selectedCustomer?.businessInformation?.description !==
        values.businessDescription
      ) {
        let businessInformation = {
          name: values.businessName ? values.businessName : "",
          address: values.businessAddress ? values.businessAddress : "",
          description: values.businessDescription
            ? values.businessDescription
            : "",
          icon:icon || ""
        };
        updateFormdata.append(
          "businessInformation",
          JSON.stringify(businessInformation)
        );
      }
      if (
        values.Website !== "" ||
        selectedCustomer?.socialMedialinks?.Website !== values.Website ||
        values.Instagram !== "" ||
        selectedCustomer?.socialMedialinks?.Instagram !== values.Instagram ||
        values.WhatsApp !== "" ||
        selectedCustomer?.socialMedialinks?.WhatsApp !== values.WhatsApp ||
        values.Snapchat !== "" ||
        selectedCustomer?.socialMedialinks?.Snapchat !== values.Snapchat
      ) {
        let socialMedialinks = {
          Website: values.Website ? values.Website : "",
          Instagram: values.Instagram ? values.Instagram : "",
          WhatsApp: values.WhatsApp ? values.WhatsApp : "",
          Snapchat: values.Snapchat ? values.Snapchat : "",
        };
        updateFormdata.append(
          "socialMedialinks",
          JSON.stringify(socialMedialinks)
        );
      }
    }

    try {
      if (selectedCustomer) {
        const response = await axiosInstance.patch(
          `/users/${selectedCustomer.id}`,
          updateFormdata
        );
        closeModal();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("userName", values.userName);
        formData.append("email", values.email);
        formData.append("phoneNumber", removeFirstMatch(values.phoneNumber, values.countryCode?.split('+')?.[1]) ?? '');
        formData.append("password", values.password);
        formData.append("roleId", values.roleId);
        formData.append("countryCode", values.countryCode);
        for (let i = 0; i < selectedPdfNames.length; i++) {
          formData.append("document", selectedPdfNames[i]);
        }
        if (
          (values.businessAddress && values.businessAddress !== "") ||
          (values.businessName && values.businessName !== "") ||
          (values.businessDescription && values.businessDescription !== "")
        ) {
          let businessInformation = {
            name: values.businessName ? values.businessName : "",
            address: values.businessAddress ? values.businessAddress : "",
            description: values.businessDescription
              ? values.businessDescription
              : "",
          };
          formData.append(
            "businessInformation",
            JSON.stringify(businessInformation)
          );
        }
        if (
          (values.Website && values.Website !== "") ||
          (values.Instagram && values.Instagram !== "") ||
          (values.WhatsApp && values.WhatsApp !== "") ||
          (values.Snapchat && values.Snapchat !== "")
        ) {
          let socialMedialinks = {
            Website: values.Website ? values.Website : "",
            Instagram: values.Instagram ? values.Instagram : "",
            WhatsApp: values.WhatsApp ? values.WhatsApp : "",
            Snapchat: values.Snapchat ? values.Snapchat : "",
          };
          formData.append("socialMedialinks", JSON.stringify(socialMedialinks));
        }
        const response = await axiosInstance.post(`/users`, formData);
        closeModal();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchAllUsers();
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.message;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setUpdateLoading(false);
  };

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <div className="mt-3">
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className="flex items-center rounded-xl bg-[#4318FF] px-5 py-2 text-white hover:bg-blue-600 focus:outline-none"
        >
          <AiOutlineUserAdd className="mr-2 h-6 w-6" />
          Add Independent Seller
        </button>
      </div>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to deactive the independent seller?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isDeleting ? "Deactivating..." : "Yes"}
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                disabled={isDeleting}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showActiveConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to active the independent seller?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleActivateUser}
                disabled={isActivating}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isActivating ? "Activating..." : "Yes"}
              </button>
              <button
                onClick={hideActivateConfirmationModal}
                disabled={isActivating}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3 overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />
          }
          noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
            type={"bubbles"}
            color={"#4318FF"}
            height={100}
            width={100}
          />}
          onChangePage={(value) => {
            setPageNumber(value);
          }}
          paginationServer
          onChangeRowsPerPage={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
          paginationTotalRows={totalRowCount}
          paginationDefaultPage={pageNumber}
          customStyles={{
            rows: {
              style: {
                minHeight: "2.5rem",
              },
            },
            headCells: {
              style: {
                fontSize: "0.9rem",
                backgroundColor: "#4318FF",
                fontWeight: "bold",
                letterSpacing: "0.05rem",
                color: "#FFFFFF",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
            cells: {
              style: {
                fontSize: "0.9rem",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          }}
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-lg rounded-lg bg-white p-4 shadow-lg">
            <div className="h-[80vh] overflow-y-auto">
              <Formik
                initialValues={initialValues}
                validationSchema={
                  editMode ? EditValidationSchema : validationSchema
                }
                onSubmit={(values) => handleFormSubmit(values)}
                enableReinitialize
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  errors,
                }) => (
                  <Form className="flex flex-col px-5">
                    <button
                      type="button"
                      onClick={() => {
                        closeModal();
                        setSelectedCustomer(null);
                      }}
                      className="mt-4 self-end rounded-full bg-red-500 p-2 hover:bg-red-600 focus:outline-none"
                    >
                      <AiFillCloseSquare className="text-white" size={24} />
                    </button>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Roles:
                      </label>
                      <select
                        id="roleSelect"
                        className="w-full rounded-md border px-3 py-2"
                        value={selectedRole}
                        disabled={true}
                      >
                        <option value="Independent Sellers">
                          Independent Sellers
                        </option>
                      </select>
                    </div>
                    <div className="mb-2" ref={inputRef}>
                      <label className="fw-bold block text-gray-700">
                        {`Independent Seller Name:`}{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="w-full rounded-md border px-3 py-2"
                        placeholder="Enter Owner Name"
                        maxLength={20}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        User Name: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                        autoComplete={false}
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Email Address: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    {editMode ? null : (
                      <div className="relative mb-3">
                        <label className="fw-bold block text-gray-700">
                          Password: <span className="text-red-500">*</span>
                        </label>
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter Password here"
                          className="w-full rounded-md border px-3 py-2 pr-10"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="absolute right-2 top-9  flex cursor-pointer items-center pr-2"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                        </span>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Mobile Number: <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <PhoneInput
                          country={values?.countryCode}
                          disableDropdown={false}
                          countryCodeEditable={false}
                          enableSearch={false}
                          placeholder="Enter Mobile Number"
                          value={values.phoneNumber}
                          onChange={(phone, dialCode, c, f) => {
                            setFieldValue("countryCode", `+${dialCode.dialCode}`);
                            setFieldValue("phoneNumber", phone);
                            if (
                              dialCode.format !==
                              String(phone).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          onBlur={async (e, dialCode) => {
                            if (
                              dialCode.format !==
                              String(e.target.value).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          containerStyle={{
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            height: "10px",
                          }}
                        />
                        {errors?.phoneNumber && (
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-red-500"
                          />
                        )}
                      </div>
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Business Name:
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="businessName"
                        placeholder="Enter Business Name"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Business Address:
                      </label>
                      <Field
                        type="text"
                        name="businessAddress"
                        placeholder="Enter Business Address"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="businessAddress"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="">
                      <label className="fw-bold block text-gray-700">
                        Business Description:
                      </label>
                      <Field
                        as="textarea"
                        placeholder="Enter Business Description"
                        name="businessDescription"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="businessDescription"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label className="fw-bold block text-gray-700">
                          Legal Document (PDF):
                        </label>
                        <button
                          type="button"
                          className="mb-3 mt-2 self-end rounded-md bg-[#4318FF] p-2 px-3 font-bold text-white focus:outline-none"
                          onClick={() => {
                            const fileInput =
                              document.getElementById("pdfInput");
                            fileInput.click();
                          }}
                        >
                          <MdDriveFolderUpload />
                        </button>
                      </div>
                      <input
                        type="file"
                        id="pdfInput"
                        className="sr-only"
                        name="document"
                        accept="application/pdf"
                        ref={pdfInputRef}
                        onChange={handlePdfInputChange}
                        onBlur={handleBlur}
                        multiple
                      />

                      {showFiles()}
                    </div>

                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Website:
                      </label>
                      <Field
                        type="text"
                        name="Website"
                        placeholder="Enter Website URL"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="Website"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Instagram:
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Instagram URL"
                        name="Instagram"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="Instagram"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        WhatsApp Number/Link:
                      </label>
                      <Field
                        type="tel"
                        name="WhatsApp"
                        placeholder="Enter WhatsApp Number/Link"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="WhatsApp"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="fw-bold block text-gray-700">
                        Snapchat:
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Snapchat URL"
                        name="Snapchat"
                        className="w-full rounded-md border px-3 py-2"
                      />
                      <ErrorMessage
                        name="Snapchat"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-theme cursor-pointer rounded-sm px-5 py-2 text-white"
                      disabled={updateloading}
                    >
                      {editMode
                        ? updateloading
                          ? "Updating..."
                          : "Update"
                        : updateloading
                          ? "Submitting..."
                          : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndependentSellersManagement;
