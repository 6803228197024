import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";
import ReactLoading from "react-loading";

const RolesAndPermissionManagement = () => {
  // const [moduleData, setModuleData] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [roleSelected, setRoleSelected] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  // const [rightsAdded, setRightsAdded] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const modules = [
    // { name: "Dashboard" },
    // { name: "Verify Users" },
    // { name: "Monetization" },
    // { name: "Coaches Management" },
    // { name: "Customers Management" },
    // { name: "Sport Center Owner Management" },
    // { name: "Independent Sellers Management" },
    { name: 'Court Management', },
    { name: 'Tournament Management', },
    { name: 'Coach Management', },
    { name: 'Schedule Management', },
    { name: 'Role And Permission' },
    { name: 'Product Management', },
    { name: 'Package Management' },
    { name: 'Coupon And Discount', },
    { name: 'Accounting' },
  ]
  const modules2 = [
    { name: 'Product Management', },
    { name: 'Package Management' },
    { name: 'Coupon And Discount', },
    { name: 'Accounting' },
  ]

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const fetchRoleOptions = async () => {
    try {
      const response = await axiosInstance.get("/roles");
      const data = response?.data?.data;
      if (data) {
        const showrole = data.filter(x => x.name !== "Manager" && x.name !== "Customer")
        setRoleOptions(showrole);
      }
    } catch (error) {
      toast.error("Error fetching role options:", error);
    }
  };
  useEffect(() => {

    // fetchModuleData();
    fetchRoleOptions();
  }, []);

  const handlePermissionChange = (module) => {
    let type = getType(module);
    let role = [...roleSelected];
    let idx = role.findIndex(r => r === type);
    if (idx > -1) {
      role.splice(idx, 1);
    } else role.push(type);
    setRoleSelected(role);
  }
  const handleUpdate = async () => {
    setLoading(true)
    try {
      setDisabled(true)
      setTimeout(() => {
        setDisabled(false)
      }, 5000);
      if (selectedRoleId) {
        const postData = {
          id: selectedRoleId,
          rights: roleSelected
        };
        const updateResponse = await axiosInstance.post(`/roles/${selectedRoleId}`, postData);
        toast.success(updateResponse?.data?.message);
      } else {
        toast.error("Please Select The Role");
      }
    } catch (error) {
      setLoading(false)
      toast.error("An error occurred. Please try again later");
    }
    finally {
      setLoading(false)
    }
    // fetchModuleData();
    fetchRoleOptions();
  };



  const getType = (name) => {
    // let type = "";
    // if (name === "Customers Management") type = "Customer";
    // else if (name.includes("Owner")) type = "Owner";
    // else if (name.includes("Seller")) type = "Seller";
    // else if (name.includes("Coach")) type = "Coach";
    // else if (name.includes("User")) type = "User";
    // else if (name.includes("Dashboard")) type = "Dashboard";
    // else if (name.includes("Monetization")) type = "Monetization";
    let nm = name?.replace(/ /g, '')
    let type = nm.charAt(0).toLowerCase() + nm.substring(1)
    return type;
  }
  const checkUI = (row) => {
    return <input
      type="checkbox"
      checked={roleSelected?.find((x) => getType(row.name) === x)}//{handleCheck(type, row.name)}
      onChange={() => handlePermissionChange(row.name)}
      // disabled={true}
      className="form-checkbox text-indigo-600"
    />
  }
  const columns = useMemo(() => {
    return [
      {
        name: "Module Name",
        // selector: "name",
        selector: (row) => row.name,
        sortable: false,
      },
      {
        name: "Status",
        cell: (row, i) => (
          <div>
            {checkUI(row)}
          </div>
        ),
      },
    ];
  }, [roleSelected]);

  const handleSelect = (e) => {
    let role = e.target.value;
    setSelectedRole(role);
    const selectedRole = roleOptions.find(item => item.name === role);
    if (selectedRole?.id) {
      setSelectedRoleId(selectedRole.id);
      let rights = selectedRole.rights;
      // setRightsAdded(rights);
      setRoleSelected(rights);
    }
  };
  // useEffect(() => {}, [roleSelected]);


  return (
    <div className="mt-2 p-6">
      <div className="mb-5">
        <label
          htmlFor="roleSelect"
          className="block font-medium text-gray-700"
        >
          Select Role:
        </label>
        <select
          id="roleSelect"
          className="mt-1 block w-full rounded border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={selectedRole}
          onChange={handleSelect}
        >
          <option value="" disabled>Select Role</option>
          {roleOptions?.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <DataTable
        title="Permissions"
        columns={columns}
        data={selectedRole.includes("Owner") ? modules : modules2}
        pagination
        highlightOnHover
        responsive
        // progressPending={loading}
        paginationComponentOptions={paginationOptions}
        // progressComponent={
        //   <ReactLoading
        //     type={"bubbles"}
        //     color={"#4318FF"}
        //     height={100}
        //     width={100}
        //   />
        // }
        customStyles={{
          rows: {
            style: {
              minHeight: "2.5rem",
            },
          },
          headCells: {
            style: {
              fontSize: "0.9rem",
              backgroundColor: "#4318FF",
              fontWeight: "bold",
              letterSpacing: "0.05rem",
              color: "#FFFFFF",
              paddingLeft: "0.75rem",
              paddingRight: "0.75rem",
            },
          },
          cells: {
            style: {
              fontSize: "0.9rem",
              paddingLeft: "0.75rem",
              paddingRight: "0.75rem",
            },
          },
        }}
      />

      <div className="flex justify-end mt-4">
        <button
          disabled={isDisabled}
          onClick={handleUpdate}
          className="bg-[#4318FF] hover:bg-blue-700 text-white py-2 px-4 rounded flex items-center font-semibold"
        >
          {
            loading ?
              <span className="flex items-center justify-center gap-2"><ReactLoading type={"spin"} color={"#fff"} height={18} width={18} /> Updating</span>
              :
              <span className="flex items-center justify-center gap-2"><FaSave /> Update</span>
          }
        </button>
      </div>
    </div>
  );

};

export default RolesAndPermissionManagement;
