import { Formik,Form,Field } from "formik";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";
import * as Yup from "yup";


const VerifyUsers = () => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);

  const [showVerifyConfirmation, setShowVerifyConfirmation] = useState(false);
  const [verifyUserId, setVerifyUserId] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);

  const [showUnverifyConfirmation, setShowUnverifyConfirmation] = useState(false);
  const [unverifyUserId, setUnverifyUserId] = useState(null);
  const [isUnverifying, setIsUnveryfying] = useState(false);

  const [sortBy, setSortBy] = useState('createdAt');
  const [sortType, setSortType] = useState('desc');
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState('');

const [searchTerm,setSearchTerm] = useState('');
const [status,setStatus] = useState('');

  const showVerifyConfirmationModal = (id) => {
    document.body.classList.add('modal-open');
    setVerifyUserId(id);
    setShowVerifyConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove('modal-open');
    setShowVerifyConfirmation(false);
    setVerifyUserId(null);
  };

  const showUnverifyConfirmationModal = (id) => {
    document.body.classList.add('modal-open');
    setUnverifyUserId(id);
    setShowUnverifyConfirmation(true);
  };
  const hideActivateConfirmationModal = () => {
    document.body.classList.remove('modal-open');
    setShowUnverifyConfirmation(false);
  };

  useEffect(() => { setData(data) }, [data])
  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/users/verify-list?page=${pageNumber}&limit=${pageSize}&sortBy=${sortBy}&sortType=${sortType}&searchTerm=${searchTerm}&type=${status}`;
      if (urlLink !== link) {
        setUrl(link);
      }
  
      const response = await axiosInstance.get(link);
      let users = response?.data?.data?.data;
      setTotalRowCount(response?.data?.data?.length);
      setData(users);
      setShowNoDataText(!response?.data?.data?.length)
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(pageNumber !== 1) {
      pageNumber(1)
    }
  },[searchTerm,status])

  useEffect(() => {
    if(searchTerm == '' && status == ''){
    fetchAllUsers();
    }
  }, [pageNumber, pageSize, isSorting,searchTerm,status]);

  useEffect(() => {
    if (searchTerm !== '' || status !== '') {
      setLoading(true);
      const searchData = setTimeout(() => {
        fetchAllUsers();
      }, 1000);
      return () => clearTimeout(searchData);
    }
  },[searchTerm,status,pageNumber, pageSize, isSorting])

  const handleActivateDeactivate = async (isVerified, verifyUserId) => {
    try {
      const response = await axiosInstance.patch(`/users/${verifyUserId}`, {
        isVerified,
      });
      if (response.status === 200) {
        if(searchTerm === '' && status === '' ){
          fetchAllUsers();
        }
        setSearchTerm('');
        setStatus('');
        (searchTerm !== '' || status !== '') && setPageNumber(1);
        setVerifyUserId(null);
        setShowVerifyConfirmation(false);
      }

      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error has been occured.", {});
      setShowVerifyConfirmation(false);
    }
  };

  const handleActivateUser = async () => {
    setIsUnveryfying(true);
    await handleActivateDeactivate(false, unverifyUserId)
    document.body.classList.remove('modal-open');
    setIsUnveryfying(false);
    setShowUnverifyConfirmation(false);
  }
  const handleDelete = async () => {
    setIsVerifying(true);
    await handleActivateDeactivate(true, verifyUserId)
    document.body.classList.remove('modal-open');
    setIsVerifying(false);
  };

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === 'asc') {
        setSortType('desc')
      }
      else if (sortType === 'desc') {
        setSortBy('createdAt')
      }
    } else {
      setSortBy(by)
      setSortType('asc')
    }
    if (pageSize !== 1) setSorting(!isSorting)
    else {
      setPageSize(10)
      setPageNumber(1)
    }
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
      // sortFunction: () => sortTable("name"),
    },
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: false,
      // sortFunction: () => sortTable("userName"),
    },
    {
      name: "Followers Count",
      selector: (row) => row.followedByIDs.length,
    },
    // {
    //   name: "Action",
    //   cell: (row) => {
    //     if (!row.isVerified) {
    //       return (
    //         <div className="flex space-x-2">
    //           <button
    //             onClick={() => showVerifyConfirmationModal(row.id)}
    //             className="hover-bg-[#4318FF] cursor-pointer rounded bg-[#4318FF] px-4 py-2 font-bold text-white"
    //           >
    //             Verify
    //           </button>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="flex space-x-2">
    //           <button
    //             onClick={() => showUnverifyConfirmationModal(row.id)}
    //             className="rounded bg-gray-500 px-5 py-2 font-bold text-white"
    //           >
    //             Verified
    //           </button>
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      name: "Action",
      cell: (row) => {
        const buttonCommonClasses = "rounded px-4 py-2 font-bold text-white";
        const isVerified = row.isVerified;
        return (
          <div className="flex space-x-2">
            <button
              onClick={() => (isVerified ? showUnverifyConfirmationModal(row.id) : showVerifyConfirmationModal(row.id))}
              className={`cursor-pointer w-24 ${isVerified ? 'bg-green-500' : 'hover:bg-[#4318FF] bg-[#4318FF]'} ${buttonCommonClasses} `}
            >
              {isVerified ? "Verified" : "Verify"}
            </button>
          </div>
        );
      },
    }

  ];


  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const validationSchema = Yup.object({
    searchData: Yup.string().required("Enter data for search")
  });

  return (
    <div className="mt-3">
      {showVerifyConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to Verify The User ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDelete}
                disabled={isVerifying}
                className="mr-2 rounded bg-[#4318FF] px-4 py-2 text-white"
              >
                {isVerifying ? "Verifying..." : "Yes"}
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                disabled={isVerifying}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showUnverifyConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to Unverify The User?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleActivateUser}
                disabled={isUnverifying}
                className="mr-2 rounded bg-[#4318FF] px-4 py-2 text-white"
              >
                {isUnverifying ? 'Unveryfying...' : 'Yes'}
              </button>
              <button
                onClick={hideActivateConfirmationModal}
                disabled={isUnverifying}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="Search">
        <Formik 
        initialValues={{
          searchData : '',
          userStatus: ''
        }}
        validationSchema={validationSchema}
        validateOnBlur = {true}
        validateOnChange={true}
        onSubmit={(values, e) => {
          e.preventDefault();
          setSearchTerm(values.searchData)
        }
      }
      >
          <Form className="flex justify-between">
          <div className="flex gap-4 flex-wrap">
          <Field id="searchField" name="searchData" type="text" className="w-[250px] rounded-md px-3 py-2 border focus:outline-blueSecondary" 
          placeholder="Search"
          value={searchTerm}
          onChange = {(e) => {
            setSearchTerm(e.target.value);
            }}
            />
            <div>
              <select name='userStatus' value={status} placeholder='Select user status'
              className="rounded-md px-3 py-2 border focus:outline-blueSecondary"
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                <option value=''>Select user Status</option>
                <option value='verified'>Verified</option>
                <option value='unVerified'>Unverified</option>
              </select>
            </div>
          </div>
          </Form>
        </Formik>
      </div>
      <div className="mt-3 overflow-x-auto">
        <DataTable
          columns={columns}
          data={data}
          pagination
          progressPending={loading}
          paginationComponentOptions={paginationOptions}
          progressComponent={
            <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />
          }
          noDataComponent={showNoDataText ? <div>There are no records to display</div> : <ReactLoading
            type={"bubbles"}
            color={"#4318FF"}
            height={100}
            width={100}
          />}
          onChangePage={(value) => {
            setPageNumber(value);
          }}
          paginationServer
          onChangeRowsPerPage={(value) => {
            setPageSize(value);
            setPageNumber(1);
          }}
          paginationTotalRows={totalRowCount}
          paginationDefaultPage={pageNumber}
          customStyles={{
            rows: {
              style: {
                minHeight: "2.5rem",
                padding:'4px 0'
              },
            },
            headCells: {
              style: {
                fontSize: "0.9rem",
                backgroundColor: "#4318FF",
                fontWeight: "bold",
                letterSpacing: "0.05rem",
                color: "#FFFFFF",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
            cells: {
              style: {
                fontSize: "0.9rem",
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default VerifyUsers;
