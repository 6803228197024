import { toast } from "react-toastify";
import axiosInstance from "./api";

export const fetchDashboardCounts = () => {
    return axiosInstance.get('/dashboard')
        .then(response => {
            return response.data;
        })
        .catch(error => {
            toast.error('An Error Has Been Occured', error);
            return null;
        });
};