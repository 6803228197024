import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { emailRegex } from "utils/constants";
import * as Yup from "yup";
import axiosInstance from "../../utils/services/api";

const LogIn = () => {
  const initialValues = {
    email: "",
    password: "",
    keepLoggedIn: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
        "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
      ),
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const fcmToken = await localStorage.getItem("deviceFCM");
      const response = await axiosInstance.post(`/auth/login`, { ...values, ...(fcmToken && { fcmToken }), device: 'web' });
      toast.success(response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.setItem("accessToken", response?.data?.data?.tokens?.access?.token)
      localStorage.setItem("refreshToken", response?.data?.data?.tokens?.refresh?.token)
      setLoading(false);
      setTimeout(() => {
        window.location.replace("/admin/dashboard");
      }, 1200);
    } catch (error) {
      if (error?.response?.data?.message) {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      } else {
        // Display a custom error message if the API call fails for any other reason
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="rounded-xl border-red-600 bg-white p-6 shadow-lg dark:bg-navy-900 form-container md:w-[400px] mx-auto w-full">
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
            Enter your email and password to proceed!
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address <span className="text-red-700">*</span>
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your Email"
                  className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-sm text-red-500 mt-1"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password <span className="text-red-700">*</span>
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Enter Your Password"
                    className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                  />
                  <span
                    className="password-toggle-icon mt-1"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-sm text-red-500 mt-1"
                />
              </div>

              <div className="mb-4 flex items-center justify-between px-2">
                <div className="flex items-center cursor-pointer">
                  <Field
                    type="checkbox"
                    id="keepLoggedIn"
                    name="keepLoggedIn"
                    className="mr-2"
                    checked={remember}
                    onClick={() => setRemember(!remember)}
                  />
                  <p className="text-sm font-medium text-navy-700 dark:text-white"
                    onClick={() => setRemember(!remember)}
                  >
                    Keep me logged In
                  </p>
                </div>
                <Link
                  to="/auth/forgot-password"
                  className="text-sm font-medium text-[#06A7E0]"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="linear mt-2 w-full rounded-xl bg-blueSecondary py-[12px] text-base font-medium text-white transition duration-200 active:bg-[#14AADD] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                disabled={loading}
              >
                {loading ? "Logging In..." : "Login"}
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div >
  );
};

export default LogIn;
