import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  AiFillCloseSquare,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { FiMinusCircle } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import ReactLoading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import axiosInstance from "utils/services/api";
import * as Yup from "yup";
import Noimage from "../../../assets/img/avatars/not_available.png";
import { emailRegex } from "utils/constants";
import { nameRegex } from "utils/constants";
import { userNameRegex } from "utils/constants";
import { useRef } from "react";
import { removeFirstMatch } from "utils/services/commonServices";


const Customers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [isSorting, setSorting] = useState(false);
  const [urlLink, setUrl] = useState("");

  const inputRef = useRef(null);
  const maxLength = 150;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Full Name"
      )
      .test(
        "no-multiple-spaces",
        "Full Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Full Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "Full Name must not exceed 20 characters"),
    userName: Yup.string()
      .required("User Name is required")
      .matches(
        userNameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the User Name"
      )
      .test(
        "no-only-special-chars",
        "User Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "User Name must not exceed 20 characters")
      .test(
        "no-multiple-spaces",
        "User Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    bio: Yup.string().required("Bio is Required").max(maxLength, `Bio must be at most ${maxLength} characters`)
      .test(
        "no-multiple-spaces",
        "Bio should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ).trim(),
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits")
    ,
    // .max(17, "Mobile number must be less than 17 digits"),
    // .test("phoneNumber", "Please enter valid mobile number", () => {
    //   return isValidPhoneNumber;
    // }),

    password: Yup.string()
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
        "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
      ),
    gender: Yup.string().required("Gender is required"),
    level: Yup.string().required("Level is required"),
  });

  const EditValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Full Name is required")
      .trim()
      .matches(
        nameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the Full Name"
      )
      .test(
        "no-multiple-spaces",
        "Full Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .test(
        "no-only-special-chars",
        "Full Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "Full Name must not exceed 35 characters"),
    userName: Yup.string()
      .required("User Name is required")
      .matches(
        userNameRegex,
        "Only alphabets, numbers, underscore (_), period (.), and hyphen (-) are allowed for the User Name"
      )
      .test(
        "no-only-special-chars",
        "User Name should not consist entirely of special characters",
        (value) => !/^[-_\\.]+$/.test(value)
      )
      .max(20, "User Name must not exceed 35 characters")
      .test(
        "no-multiple-spaces",
        "User Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    bio: Yup.string().required("Bio is Required").max(maxLength, `Bio must be at most ${maxLength} characters`)
      .test(
        "no-multiple-spaces",
        "Bio should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ).trim(),
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .test("checkEmptyValue", "Mobile number is required", function (value) {
        const { countryCode } = this.parent;
        return `+${value}` !== countryCode
      })
      .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
        const { countryCode } = this.parent;
        return removeFirstMatch(value, countryCode?.split('+')?.[1])?.length >= 4
      })
      .matches(/^[0-9]+$/, "Mobile number is must be only digits"),
    gender: Yup.string().required("Gender is required"),
    level: Yup.string().required("Level is required"),
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);
  const [activateUserId, setActivateUserId] = useState(null);
  const [isActivating, setIsActivating] = useState(false);

  const showDeleteConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const showActivateConfirmationModal = (id) => {
    document.body.classList.add("modal-open");
    setActivateUserId(id);
    setShowActiveConfirmation(true);
  };
  const hideActivateConfirmationModal = () => {
    document.body.classList.remove("modal-open");
    setShowActiveConfirmation(false);
  };

  const handleImageChange = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedFormats.includes(file.type)) {
        setProfilePic(file);
        const imageURL = URL.createObjectURL(file);
        document.getElementById("previewImage").src = imageURL;
      } else {
        toast.error(
          "Invalid file format. Please upload a jpg, jpeg, or png file.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        event.target.value = null;
      }
    } else {
      setProfilePic("");
      document.getElementById("previewImage").src = Noimage;
    }
  };

  const fetchRole = async () => {
    try {
      const response = await axiosInstance.get("/roles");
      const data = response?.data?.data;
      setRoleOptions(data);

      const customerRole = data?.find((role) => role.name === "Customer");
      if (customerRole) {
        setSelectedRole(customerRole.id);
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    roleId: selectedRole,
    name: selectedCustomer ? selectedCustomer?.name : "",
    userName: selectedCustomer ? selectedCustomer?.userName : "",
    email: selectedCustomer ? selectedCustomer?.email : "",
    bio: selectedCustomer ? selectedCustomer?.bio : "",
    countryCode: editMode && selectedCustomer?.countryCode ? selectedCustomer?.countryCode : "+91",
    phoneNumber: editMode && selectedCustomer?.phoneNumber ? selectedCustomer?.countryCode?.split?.('+')?.[1] + selectedCustomer?.phoneNumber : "91",
    password: selectedCustomer ? selectedCustomer?.password : "",
    gender: selectedCustomer ? selectedCustomer?.gender : "Male",
    level: selectedCustomer ? selectedCustomer?.level : "Beginner",
    profilePic: selectedCustomer ? selectedCustomer?.profilePic : "",
  };


  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    setEditMode(false);
    setSelectedCustomer(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setEditMode(false);
  };
  useEffect(() => {
    setData(data);
  }, [data]);
  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      let link = `/users?page=${pageNumber}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
      let substring = `${sortBy}&sortType=${sortType}`;
      if (urlLink !== substring) {
        link = `/users?page=${1}&limit=${pageSize}&roleId=${selectedRole}&sortBy=`;
        setUrl(substring);
      }
      if (selectedRole) {
        const response = await axiosInstance.get(link + substring);

        let users = response?.data?.data?.data;

        setTotalRowCount(response?.data?.data?.length);
        setShowNoDataText(!response?.data?.data?.length)
        setData(users);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [pageNumber, pageSize, selectedRole, isSorting]);

  const handleEdit = (row) => {
    document.body.classList.add("modal-open");
    setSelectedCustomer(row);
    setIsModalOpen(true);
    setEditMode(true);
  };
  const handleActivateDeactivate = async (isDeleted, deleteUserId) => {
    try {
      const response = await axiosInstance.patch(`/users/${deleteUserId}`, {
        isDeleted,
      });

      if (response?.status === 200) {
        fetchAllUsers();
        setDeleteUserId(null);
        setShowDeleteConfirmation(false);
      }
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error("An error occurred while deleting the customer.", {});
    }
  };

  const handleActivateUser = async () => {
    setIsActivating(true);
    await handleActivateDeactivate(false, activateUserId);
    setIsActivating(false);
    setShowActiveConfirmation(false);
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    await handleActivateDeactivate(true, deleteUserId);
    setIsDeleting(false);
  };

  const sortTable = (by) => {
    if (by === sortBy) {
      if (sortType === "asc") {
        setSortType("desc");
      } else if (sortType === "desc") {
        setSortBy("createdAt");
      }
    } else {
      setSortBy(by);
      setSortType("asc");
    }
    if (pageSize !== 1) setSorting(!isSorting);
    else {
      setPageSize(10);
      setPageNumber(1);
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortFunction: () => sortTable("name"),
    },
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: true,
      sortFunction: () => sortTable("userName"),
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.countryCode + " " + row.phoneNumber,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => {
        if (!row.isDeleted) {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(row)}
                className="cursor-pointer rounded bg-[#4318FF] px-4 py-2 font-bold text-white hover:bg-blue-700"
              >
                <BiEdit />
              </button>
              <button
                onClick={() => showDeleteConfirmationModal(row.id)}
                className="hover-bg-red-700 cursor-pointer rounded bg-red-500 px-4 py-2 font-bold text-white"
              >
                <FiMinusCircle />
              </button>
            </div>
          );
        } else {
          return (
            <div className="flex space-x-2">
              <button
                onClick={() => showActivateConfirmationModal(row.id)}
                className="rounded bg-gray-500 px-5 py-2 font-bold text-white"
              >
                Deactive
              </button>
            </div>
          );
        }
      },
    },
  ];

  const handleFormSubmit = async (values) => {
    setUpdateLoading(true);
    // setLoading(true);
    // closeModal();
    // values.phoneNumber = values.phoneNumber.substring(countrycode.length);
    values.profilePic = profilePic;

    const updateFormdata = new FormData();

    if (selectedCustomer) {
      if (values.name !== selectedCustomer.name) {
        updateFormdata.append("name", values.name);
      }
      if (values.userName !== selectedCustomer.userName) {
        updateFormdata.append("userName", values.userName);
      }
      if (values.email !== selectedCustomer.email) {
        updateFormdata.append("email", values.email);
      }
      if (values.bio !== selectedCustomer.bio) {
        updateFormdata.append("bio", values.bio);
      }
      if (values.phoneNumber !== selectedCustomer.phoneNumber) {
        updateFormdata.append("phoneNumber", removeFirstMatch(values?.phoneNumber, values.countryCode?.split('+')?.[1]) ?? '');
      }
      if (values.password !== selectedCustomer.password) {
        updateFormdata.append("password", values.password);
      }
      if (values.gender !== selectedCustomer.gender) {
        updateFormdata.append("gender", values.gender);
      }
      if (values.level !== selectedCustomer.level) {
        updateFormdata.append("level", values.level);
      }
      if (values.profilePic) {
        updateFormdata.append("profilePic", values.profilePic);
      }
      if (values.countryCode !== selectedCustomer.countryCode) {
        updateFormdata.append("countryCode", values.countryCode);
      }
    }


    try {
      if (selectedCustomer) {
        const response = await axiosInstance.patch(
          `/users/${selectedCustomer.id}`,
          updateFormdata
        );
        closeModal();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("userName", values.userName);
        formData.append("email", values.email);
        formData.append("bio", values.bio);
        formData.append("phoneNumber", removeFirstMatch(values.phoneNumber, values.countryCode?.split('+')?.[1]) ?? '');
        formData.append("password", values.password);
        formData.append("gender", values.gender);
        formData.append("roleId", values.roleId);
        formData.append("level", values.level);
        formData.append("profilePic", values.profilePic);
        formData.append("countryCode", values.countryCode);


        const response = await axiosInstance.post(`/users`, formData);
        closeModal();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchAllUsers();
    } catch (error) {
      if (error) {
        const errorMessage = error?.response?.data?.message;
        errorMessage && toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setUpdateLoading(false);
  };

  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  return (
    <div className="mt-3">
      <div className="flex justify-end">
        <button
          onClick={openModal}
          className="flex items-center rounded-xl bg-[#4318FF] px-5 py-2 text-white hover:bg-blue-600 focus:outline-none"
        >
          <AiOutlineUserAdd className="mr-2 h-6 w-6" />
          Add Customer
        </button>
      </div>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to deactive the customer?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isDeleting ? "Deactivating..." : "Yes"}
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                disabled={isDeleting}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {showActiveConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure want to active the customer?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleActivateUser}
                disabled={isActivating}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                {isActivating ? "Activating..." : "Yes"}
              </button>
              <button
                onClick={hideActivateConfirmationModal}
                disabled={isActivating}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3 overflow-x-auto">
        <div>
          <DataTable
            columns={columns}
            data={data}
            pagination
            progress
            progressPending={loading}
            progressComponent={
              <ReactLoading
                type={"bubbles"}
                color={"#4318FF"}
                height={100}
                width={100}
              />
            }
            noDataComponent={showNoDataText ? 'There are no records to display' : <ReactLoading
              type={"bubbles"}
              color={"#4318FF"}
              height={100}
              width={100}
            />}

            onChangePage={(value) => {
              setPageNumber(value);
            }}
            page={pageNumber}
            paginationComponentOptions={paginationOptions}
            paginationServer
            onChangeRowsPerPage={(value) => {
              setPageSize(value);
              setPageNumber(1);
            }}
            paginationTotalRows={totalRowCount}
            paginationDefaultPage={pageNumber}
            customStyles={{
              rows: {
                style: {
                  minHeight: "2.5rem",
                },
              },
              headCells: {
                style: {
                  fontSize: "0.9rem",
                  backgroundColor: "#4318FF",
                  fontWeight: "bold",
                  letterSpacing: "0.05rem",
                  color: "#FFFFFF",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                },
              },
              cells: {
                style: {
                  fontSize: "0.9rem",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                },
              },
            }}
          />
        </div>
      </div>


      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-lg rounded-lg bg-white p-4 shadow-lg">
            <div className="h-[80vh] overflow-y-auto">
              <Formik
                initialValues={initialValues}
                validationSchema={
                  editMode ? EditValidationSchema : validationSchema
                }
                onSubmit={(values) => handleFormSubmit(values)}
                enableReinitialize={true}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  errors,
                }) => (
                  <Form className="relative flex flex-col px-5">
                    <button
                      type="button"
                      onClick={() => {
                        closeModal();
                        setSelectedCustomer(null);
                      }}
                      className="mt-4 self-end rounded-full bg-red-500 p-2 hover:bg-red-600 focus:outline-none"
                    >
                      <AiFillCloseSquare className="text-white" size={24} />
                    </button>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Roles:
                      </label>
                      <select
                        id="roleSelect"
                        className="w-full rounded-md border px-3 py-2"
                        value={selectedRole}
                        disabled={true}
                      >
                        <option value="Customer">Customer</option>
                      </select>
                    </div>
                    <div className="mb-3" ref={inputRef}>
                      <label className="fw-bold block text-gray-700">
                        Full Name: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Enter Full Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full rounded-md border px-3 py-2"
                        maxLength={20}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        User Name: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={20}
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="relative">
                      <label className="fw-bold block text-gray-700">
                        Bio: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="textarea"
                        name="bio"
                        placeholder="Enter Bio"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div className="flex justify-between">
                      <ErrorMessage
                        name="bio"
                        component="div"
                        className="text-red-500 mb-3"
                      />

                      <div className={`ml-auto text-sm ${values.bio && values.bio.length > maxLength ? 'text-red-500 text-sm' : 'text-gray-700 text-sm'}`}>
                        {`${values.bio ? values.bio.length : 0} / ${maxLength}`}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Email: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        className="w-full rounded-md border px-3 py-2"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Mobile Number: <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <PhoneInput
                          country={values?.countryCode}
                          disableDropdown={false}
                          countryCodeEditable={false}
                          enableSearch={false}
                          placeholder="Enter Mobile Number"
                          value={values.phoneNumber}
                          onChange={(phone, dialCode, c, f) => {
                            setFieldValue("countryCode", `+${dialCode.dialCode}`);
                            setFieldValue("phoneNumber", phone);
                            if (
                              dialCode.format !==
                              String(phone).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          onBlur={async (e, dialCode) => {
                            if (
                              dialCode.format !==
                              String(e.target.value).replace(/[0-9]/g, ".")
                            ) {
                              setIsValidPhoneNumber(false);
                            } else {
                              setIsValidPhoneNumber(true);
                            }
                          }}
                          containerStyle={{
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            height: "10px",
                          }}
                        />
                        {errors?.phoneNumber && (
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="text-red-500"
                          />
                        )}
                      </div>
                    </div>
                    {editMode ? null : (
                      <div className="relative mb-3">
                        <label className="fw-bold block text-gray-700">
                          Password: <span className="text-red-500">*</span>
                        </label>
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter Password"
                          className="w-full rounded-md border px-3 py-2 pr-10"
                          value={values.password}
                          style={{
                            msReveal: 'none !important',
                            msClear: 'none !important',
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          className="absolute right-2 top-9  flex cursor-pointer items-center pr-2"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                        </span>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Profile Picture:
                      </label>
                      <input
                        accept=".jpg, .jpeg, .png"
                        type="file"
                        // accept="image/*"
                        onChange={handleImageChange}
                        className="w-full rounded-md border px-3 py-2"
                      />
                    </div>

                    <img
                      id="previewImage"
                      className="mb-3 w-52 border"
                      src={
                        selectedCustomer && selectedCustomer.profilePic
                          ? selectedCustomer.profilePic
                          : Noimage
                      }
                      alt="Profile Pic"
                    />

                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Gender: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        name="gender"
                        className="w-full rounded-md border px-3 py-2"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="fw-bold block text-gray-700">
                        Level: <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        name="level"
                        className="w-full rounded-md border px-3 py-2"
                      >
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                      </Field>
                      <ErrorMessage
                        name="level"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <button
                      type="submit"
                      className="cursor-pointer rounded-sm bg-[#4318FF] px-5 py-2 text-white"
                      disabled={updateloading}
                    >
                      {editMode
                        ? updateloading
                          ? "Updating..."
                          : "Update"
                        : updateloading
                          ? "Submitting...."
                          : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
};

export default Customers;